import React from 'react'
import image1 from "../Assets/WebImages/paymentimage.png"
import image2 from "../Assets/WebImages/payimages (2).png"
import { useTranslation } from 'react-i18next';

const RightSliderImageCom = () => {
  const { t } = useTranslation();

  return (
    <div>
        <div className='flex flex-wrap justify-content-space-evenly h5-edit'>
            <div>
                <img src={image1} alt="" />
                <h5>{t("Chocolates")}</h5>
            </div>
            <div>
                <img src={image2} alt="" />
                <h5>{t("Tourism & exhibition")}</h5>
            </div>
            <div>
                <img src={image2} alt="" />
                <h5>{t("Wedding planning")}</h5>
            </div>
            <div>
                <img src={image2} alt="" />
                <h5>{t("Software solutions")}</h5>
            </div>
        </div>
    </div>
  )
}

export default RightSliderImageCom