import React, { useContext, useEffect, useState } from "react";
import AllBanners from "../Components/AllBanners";
import Footer from "../Components/Footer";
import { Button } from "@mui/material";
import TopHeader from "../Components/TopHeader";
import DrawerAppBar from "../Components/AppHeader";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as Yup from 'yup';
import axios from "axios";
import config from "../Services/Config.json";
import usePost from "../CustomHooks/UsePost";
import { toast } from "react-hot-toast";
import { MainUsersContext } from "../Context/UserContext";
import { useNavigate } from "react-router-dom";
import AppLoader from "../Components/AppLoader";

gsap.registerPlugin(ScrollTrigger);

function ContactUs() {
  const { t } = useTranslation();
  const [res, apiMethod] = usePost();
  const { UserCredential } = useContext(MainUsersContext);
  const navigate = useNavigate()
  const [Attempts, setAttempts] = useState(0);
  const [Isloading, setIsloading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsloading(false);
    }, 2000);
    return () => clearTimeout(timeoutId);

  }, []);


  const initialValues = {
    name: '',
    email: '',
    message: '',
  };

  const validationSchemaContact = Yup.object().shape({
    name: Yup.string()
      .transform((value) => value.trim())
      .min(3, t("Too Short"))
      .required(t("required")),
    email: Yup.string().email(t('Invalid email')).required(t(`Email is required`)),
    message: Yup.string().trim().required(t("Message Required")),
  });

  useEffect(() => {
    if (res?.data) {
      toast(t(res?.data?.message), {
        duration: 4000,
        position: "top-right",
        style: {
          background: '#75c936',
          color: "#fff",
          minWidth: 200
        },
      });
    }
  }, [res?.data]);

  const SendMsg = (values, { resetForm }) => {

    if (UserCredential?.id && Attempts < 1) {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("detail", values.message);
      formData.append("email", values.email);
      apiMethod("contact/submit", formData);
      resetForm();
    } else {
      if (Attempts < 1) {
        toast(
          <div style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'center' }}>
            <span>Please log in to continue! </span>
            <span
              style={{
                color: '#418E30',
                fontWeight: 'bold',
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={() => navigate(`${config.ddemoss}/login`)}
            >
              LOGIN
            </span>
          </div>,
          {
            duration: 3000,
            position: "top-right",
            style: {
              background: '#F9C23C',
              color: "#fff",
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '16px',
              borderRadius: '8px',
            },
            onClose: () => navigate(`${config.ddemoss}/login`) // Optional: navigate on toast close as well
          }
        );
        setAttempts(1)
      }
    }
  };

  useEffect(() => {
    gsap.set(".contact_form, .contactimg", { opacity: 0, x: 100 });

    ScrollTrigger.create({
      trigger: ".contact_form, .contactimg",
      start: "top 80%",
      onEnter: () => {
        gsap.to(".contact_form, .contactimg", {
          opacity: 1,
          x: 0,
          duration: 0.9,
          ease: "power1.out",
        });
      },
      once: true,
    });
  }, []);

  useEffect(() => {
    gsap.set(".contactimg", { opacity: 0, x: -100 });

    ScrollTrigger.create({
      trigger: ".contactimg",
      start: "top 80%",
      onEnter: () => {
        gsap.to(".contactimg", {
          opacity: 1,
          x: 0,
          duration: 0.9,
          ease: "power1.out",
        });
      },
      once: true,
    });
  }, []);

  return (
    <div className="contact_US">
      <AppLoader Isloader={Isloading} />
      <TopHeader />
      <DrawerAppBar />
      <AllBanners title={"Contact Us"} />

      <div className="maincontact container my-5">
        <div className="contactimg">
          <img src={require("../Assets/WebImages/contactImg.png")} alt="" />
        </div>

        <div className="contact_form">
          <h3>{t("CONTACTUS")}</h3>
          <div>
            <h2>{t("LetsCollaborateNow")}</h2>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaContact}
            onSubmit={(values, formikHelpers) => SendMsg(values, formikHelpers)}
            validateOnChange
          >
            {(formik) => (
              <Form>
                <label>
                  {t("FullName")}:
                  <Field
                    type="text"
                    name="name"
                    placeholder={t("YourName")}
                  />
                  <ErrorMessage
                    name="name"
                    component='div'
                    className='error-message mt-1'
                  />
                </label>
                <label>
                  {t("Email")}:
                  <Field
                    type="email"
                    name="email"
                    placeholder={t("YourEmail")}
                  />
                  <ErrorMessage
                    name="email"
                    component='div'
                    className='error-message mt-1 '
                  />
                </label>
                <label>
                  {t("Message")}:
                  <Field
                    as="textarea"
                    name="message"
                    placeholder={t("WriteMessage...")}
                  />
                  <ErrorMessage
                    name="message"
                    component='div'
                    className='error-message mt-1'
                  />
                </label>
                <Button
                  type="submit"
                  className="contactbtn"
                  variant="contained"
                  size="medium"
                >
                  {t("SendMessage")}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
