import { useContext } from "react";
import PlusImage from "../Assets/WebImages/plus.png";
import checktick from "../Assets/WebImages/checktick.png";
import api from "../Services/httpService";
import { MainUsersContext } from "../Context/UserContext";
import { useTranslation } from "react-i18next";

const PaySubcriptionCom = () => {
  const { UserCredential } = useContext(MainUsersContext);
  const { t } = useTranslation();

  const generateRandomId = () => {
    const length = 10;
    const randomId = Array.from({ length }, () =>
      Math.floor(Math.random() * 10)
    ).join("");
    return randomId;
  };

  const handlepaySubscription = () => {
    const paySubscription = async () => {
      const randomId = generateRandomId();

      const requestBody = {
        products: [
          {
            name: "Yearly Subscription",
            description: "Yearly Subscription for the user",
            price: 7,
            quantity: 1,
          },
        ],
        order: {
          id: `${randomId}`,
          description: "Purchase order received for subscription",
          currency: "KWD",
          amount: 7,
        },
        paymentGateway: {
          src: "knet",
        },
        language: "en",
        reference: {
          id: `${randomId}`,
        },
        customer: {
          uniqueId: `${randomId}`,
          name: `${UserCredential.name}`,
          email: `${UserCredential.email}`,
          mobile: `${UserCredential.whatsapp}`,
        },
        returnUrl: `https://admin.q8hafeez.com/api/subscription-paid/${UserCredential.id}`,
        cancelUrl: "https://q8hafeez.com/paysubscription",
        notificationUrl: `https://admin.q8hafeez.com/api/subscription-paid/${UserCredential.id}`,
        customerExtraData: "User define data",
      };

      const response = await api.post(
        "https://uapi.upayments.com/api/v1/charge",
        requestBody,
        {
          headers: {
            Authorization: `Bearer fa8633144a643847b6b86180865c1425c1b65c58`,
            Accept: "Accept: application/json",
          },
        }
      );
      const { data } = response;

      if (data.status) {
        window.location.href = data.data.link;
      }
    };

    paySubscription();
  };

  return (
    <div className="PaySubcriptionCom">
      <div className="PaySubcriptionCom-div">
        <div className="d-flex border-bottom-418e30">
          <img src={PlusImage} alt="" />
          <h3>{t("Subscription")}</h3>
        </div>
        <div className="PaySubcriptionCom-spacing">
          <p className="mt-3">
            <b>{t("Choose the best plan for you")}</b>
          </p>
        </div>
        <div className="PaySubcriptionCom-spacing flex">
          <div className="paysubdiv">
            <div
              className="d-flex justify-content-center w-100 py-2 h-25"
              style={{
                background: "#418E30",
                borderRadius: "12px 12px 0px 0px",
              }}
            >
              <h6 className="text center text-white">Popular</h6>
            </div>
            <div
              className="d-flex flex-column w-100 h-75"
              style={{
                background: "rgb(155 203 132 / 76%)",
                borderRadius: "0px 0px 12px 12px",
              }}
            >
              <h4
                className="text-black text-center py-2 "
                style={{ fontWeight: "bold" }}
              >
                7 KD
              </h4>
              <div className="text-center mb-2">
                <img
                  src={checktick}
                  alt=""
                  style={{
                    padding: "12px",
                    borderRadius: "50%",
                    background: "#418E30",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="color-54AC2A">
            <p>
            {t("To enjoy the privilege of using and adding products, we kindly request you to subscribe with us. By subscribing, you will have access to exclusive benefits such as:")}
            </p>

            <ul>
              <li>{t("Adding new products effortlessly")}</li>
              <li>{t("Enhanced product management features")}</li>
              <li>{t("Priority customer support")}</li>
            </ul>
            <p>{t("Please note that the subscription is valid for one year from the subscription date.")}
              
            </p>
          </div>
        </div>
        <br />
        <div className="PaySubcriptionCom-spacing">
          <button onClick={handlepaySubscription}>{t("Pay Subscription")}</button>
        </div>
        <br />
      </div>
    </div>
  );
};

export default PaySubcriptionCom;
