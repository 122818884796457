import axios from 'axios';
import React, { useState } from 'react'
import config from '../Services/Config.json'
import { getTokenSession } from '../MainFunction';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
const ChangeEmailCom = ({data}) => {
  const [changeEmail,setchangeEmail]=useState()
  const authentication = getTokenSession();
  const { t } = useTranslation();

  // console.log(authentication,data?.id);
  // const fillModel = (key, value) => {
  //   setchangeEmail((prevState) => ({
  //     ...prevState,
  //     [key]: value,
  //   }));
  // };
  

  const submitemail=async (e)=>{
        e.preventDefault()
        if(changeEmail){
          const response= await axios.post(`https://admin.q8hafeez.com/api/update-email`,{email:changeEmail},{
            headers:{
              Authorization: `Bearer ${authentication}`
            }
          })
          // console.log(response);
            if(response){
                if(response?.data?.message == "email updated"){
                  // console.log("updated");
                  toast.success(response?.data?.message)
                  return
                }
                if(response?.data?.message == "Validation errors"){
                  // console.log("updated");
                  toast.error(response?.data?.message )
                  return
                }
                
            }
        }
  }
  
  return (
    <div className='ChangeEmailCom'>
      <div className='ChangeEmailCom-div'>
        <h3>{t("Change Email")}</h3>
        <form onClick={submitemail}>
        <div className='div-input-email-page'>
          <p style={{color:"#418e30"}}>{t("Old Email")}</p>
        <input className='input-email-page' placeholder={data?.email} disabled type="text" />
        </div>
        <div className='div-input-email-page'>
        <input className='input-email-page'  placeholder={t("New Email")} onChange={(e)=>setchangeEmail(e.target.value)} type="email" />
        </div>
        {/* <div className='div-input-email-page'>
        <input className='input-email-page' placeholder='Confirm email'  type="text" />
        </div> */}
        <div className='div-input-email-page'>
            <button className='button-input'>{t("Update")} </button>
        </div>
        </form>
      </div>
    </div>
  )
}

export default ChangeEmailCom
