import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopHeader from "../Components/TopHeader";
import AppHeader from "../Components/AppHeader";
import AllBanners from "../Components/AllBanners";
import Footer from "../Components/Footer";
import AppLoader from "../Components/AppLoader";
import PaySubcriptionCom from "../Components/PaySubcriptionCom";
import RightSliderCom from "../Components/RightSliderCom";

import config from "../Services/Config.json"
import api from "../Services/httpService";
import { getTokenSession } from '../MainFunction';


const PaySubscription = () => {
  const [Isloader,setIsLoader] = useState(true)
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  }, [Isloader]);

  useEffect(() => {
    const fetchUserSubscription = async () => {
      const userSubscription = await api.get(
        `${config.apiEndPoint}has-subscription`,
        {
          headers: {
              Authorization: `Bearer ${getTokenSession()}`
          }
        }
      )

      let { subscription } = userSubscription.data.data

      if (subscription) {
        navigate('/addproduct');
      }
    }

    fetchUserSubscription();
  }, []);

  return (
    
    <div>
      <AppLoader Isloader={Isloader} />
      {/* <AppLoader Isloader={Isloader}/> */}
      <TopHeader />
      <AppHeader />
      <AllBanners title={"Pay Subscription"} />
      <div className="flex flex-direction-column">
        <PaySubcriptionCom />
        <RightSliderCom />
      </div>
      <Footer />
    </div>
  );
  
};

export default PaySubscription;
