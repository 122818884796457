import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";

const LeftSideBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.pathname);
    const { t } = useTranslation();
    const handleClick = (path) => {
        navigate(path);
        setActiveTab(path);
    }

    return (
        <div className='main-slide-bar'>
            <div className='main-left-slide-bar'>
                <div className='left-slide-bar'>
                    <div onClick={() => handleClick(`/profile`)} className={activeTab === "/profile" ? "backgroundd9d9d9" : ""}>{t("profile")}</div>
                    <div onClick={() => handleClick(`/changeemail`)} className={activeTab === "/changeemail" ? "backgroundd9d9d9" : ""}>{t("Change Email")}</div>
                    <div onClick={() => handleClick(`/changepassword`)} className={activeTab === "/changepassword" ? "backgroundd9d9d9" : ""}>{t("Change Password")}</div>
                    <div onClick={() => handleClick(`/Favourite`)} className={activeTab === "/Favourite" ? "backgroundd9d9d9" : ""}>{t("Favorite")}</div>
                    <div onClick={() => handleClick(`/notification`)} className={activeTab === "/notification" ? "backgroundd9d9d9" : ""}>{t("Notification")}</div>
                </div>
            </div>
        </div>
    );
}

export default LeftSideBar;
