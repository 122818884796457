import Button from "@mui/material/Button";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Drawer, Menu, Select } from 'antd';
import { FaBars, } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { MainUsersContext } from "../Context/UserContext";
import { Dropdown } from 'antd';
import { CgProfile } from "react-icons/cg";
import { GoHome } from "react-icons/go";
import { TbCategory } from "react-icons/tb";
import { MdPhoneInTalk } from "react-icons/md";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoBagAddOutline } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { MdLogin } from "react-icons/md";
import { motion, } from "framer-motion"
import config from "../Services/Config.json"
import logo1 from "../Assets/WebImages/WebLogo.png"
import logo2 from "../Assets/WebImages/applogo2.png"
import { ClearTokenSession , getTokenSession } from "../MainFunction";
import { languageContextProvider } from "../Context/LangaugeContext";
import { RiLogoutCircleLine } from "react-icons/ri";
import { MdAddBusiness } from "react-icons/md";

// import { SlBadge } from "react-icons/sl";
function DrawerAppBar({ theme }) {
  const [open, setOpen] = useState(false);
  const { UserCredential, HandleUserCredential } = useContext(MainUsersContext);
  const { handleLanguageChange_Context } = useContext(languageContextProvider);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { } = useTranslation();

  const navigate = useNavigate()

  const logOut = () => {
    navigate(`/${config.ddemoss}`);
    HandleUserCredential()
    ClearTokenSession()
  };

  const provinceData = ["English", "Arabic"];

  const handleChangeLang = (value) => {
    // if (value === "Arabic") {
    //   i18n.changeLanguage("arabic");
    //   localStorage.setItem("language", "arabic");
    // } else {
    //   i18n.changeLanguage("english");
    //   localStorage.setItem("language", "english");
    // }
    handleLanguageChange_Context(value)
  };
  const storedLanguage = localStorage.getItem("language");

  useEffect(() => {
    i18n.changeLanguage(storedLanguage);
  }, []);
  
  const token = getTokenSession();

  const items = [
    {
      key: '1',
      label: (
        <Link className="d-flex align-items-center gap-1" to={`/${config.ddemoss}profile`}>
          <CgProfile size={20} className="icons" /> {t("SETTINGS")}
        </Link >
      ),
    },
    UserCredential?.type == "merchant" && {
      key: '2',
      label: (
        <Link className="d-flex align-items-center gap-1" to={`/${config.ddemoss}paysubscription`}>
          <MdAddBusiness size={20} /> {t("addProduct")}
        </Link>
      ),
    },
    {
      // key: '3',
      label: (
        <Link to={`/${config.ddemoss}`} className="d-flex align-items-center gap-1" onClick={logOut}>
          <RiLogoutCircleLine size={20} /> {t("logout")}
        </Link>
      ),
    },
  ];



  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  function Drawerheader() {
    return (
      <div className="d-flex align-items-center  justify-content-between w-100">
        <img src={require("../Assets/WebImages/applogo.png")} width={60} height={62} alt="" />
        <RxCross1 onClick={() => onClose(false)} color="#fff" className="drawer-cross" />
      </div>
    )
  }



  const handleMenuClick = (value) => {
    // console.log('Selected item:', value.key);
  }
  const menu = (
    <Menu onClick={handleMenuClick} items={items} />
  );

  return (
    <>
      <div className="app-header-main-section" style={{ background: theme ? theme : "#000000" }}>
        <div className="weblogo">
          <Link to={`/${config.ddemoss}`} >
            <img
              src={`${theme ? logo1 : logo2}`}
              alt="logo"
            />

          </Link>
        </div>
        <div className="app-header-content">
          <div className="header-section-pages">
            <div className={`header-section-pages-each ${location.pathname === "/" ? 'routeActive' : ''}`}>
              <Link style={{ color: !theme && "#fff" }} to={`/${config.ddemoss}`}> {t("home")}</Link>
            </div>

            <div className={`header-section-pages-each ${location.pathname === "/aboutus" ? 'routeActive' : ''}`}>
              <Link style={{ color: !theme && "#fff" }} to={`/${config.ddemoss}aboutus`}>{t("aboutUs")}</Link>
            </div>

            <div className={`header-section-pages-each ${location.pathname === "/category" ? 'routeActive' : ''}`}>
              <Link style={{ color: !theme && "#fff" }} to={`/${config.ddemoss}category`}> {t("category")}</Link>
            </div>

            <div className={`header-section-pages-each ${location.pathname === "/contact" ? 'routeActive' : ''}`}>
              <Link style={{ color: !theme && "#fff" }} to={`/${config.ddemoss}contact`}>{t("contactus")}</Link>
            </div>
          </div>
          <div className="header-main-btns">
            {
              UserCredential?.id || token ?
                <div className="w-100">
                  <Dropdown overlay={menu} placement="bottom" trigger={['click']}>
                    <div>
                      <Button className='logout' variant="contained" size="medium">
                     
                        <div className="userimage-head">
                       
                          {/* {UserCredential?.image || token (
                            <>
                               
                              <img
                                className="userimg-head"
                                src={UserCredential?.image}
                                alt="UserImage"
                              />
                            </>
                          )
                          } */}
                          {
                            UserCredential?.image || token ?(
                              <img
                              className="userimg-head"
                              src={UserCredential?.image}
                              alt="UserImage"
                            />
                            ):''
                          }
                        </div>
                      </Button>
                    </div>
                  </Dropdown>
                </div>
                :
                <>
                  <div className="w-100">
                    <Button className='hd-btns' variant="contained" size="medium" onClick={() => navigate(`/${config.ddemoss}login`)}  >
                      {t("login")}
                    </Button>
                  </div>
                  <div className="w-100">
                    <Button className='hd-btns' variant="contained" size="medium" onClick={() => navigate(`/${config.ddemoss}signup`)}  >
                      {t("signup")}
                    </Button>
                  </div>
                </>
            }
          </div>
        </div>
      </div >
      <div className="res-header-web" >
        <FaBars size={25} onClick={showDrawer} color="#fff" />
        <Select
          defaultValue={storedLanguage || provinceData[0]}
          style={{
            width: 120,
          }}
          className="Custom-top-header-select2"
          onChange={handleChangeLang}
          options={provinceData.map((province) => ({
            label: province,
            value: province,
          }))}
        />
      </div>
      <Drawer
        className={`res-header-web-drwar ${open ? 'open-drawer' : ''}`}
        title={<Drawerheader />}
        placement={"left"}
        closable={false}
        onClose={onClose}
        open={open}
        style={{ width: '320px' }}
        key={"left"}
      >

        {/* mobile Screen UI  .................................... */}

        <motion.div className="res-routescontainer" initial={{ x: 320 }} animate={{ x: open ? 0 : -320 }} transition={{ type: 'spring', damping: open ? 20 : 30, stiffness: open ? 100 : 200, duration: 0.9 }}>
          <div className="header-main-btns" style={{ paddingBottom: "30px" }}>
            {UserCredential?.id && (
              <div className="w-100" style={{ marginBottom: '30px', display: 'flex', alignItems: 'center', }}>
                <img src={UserCredential?.image} alt="" width={60} height={60} style={{ borderRadius: 100, objectFit: "cover" }} />
                <div className="user-names-container" style={{ marginLeft: '10px' }}>
                  <div className="user-name2">
                    {UserCredential?.name.replace(/\s+/g, '').length > 12
                      ? `${UserCredential?.name.replace(/\s+/g, '')[0].toUpperCase()}${UserCredential.name.replace(/\s+/g, '').slice(-1)}`
                      : t(UserCredential?.name)}
                  </div>
                  <div className="user-name1">
                    {t(UserCredential.type.charAt(0).toUpperCase() + UserCredential.type.slice(1))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="links">
            <Link to={`/${config.ddemoss}`} className={`links res-routes ${location.pathname === "/" ? 'Activeroute' : ''}`} >
              <GoHome className="icons" size={20} />
              {t("home")}
            </Link>
          </div>
          <div className="links" >
            <Link to={`/${config.ddemoss}aboutus`} className={`links res-routes ${location.pathname === "/aboutus" ? 'Activeroute' : ''}`} >
              <IoIosInformationCircleOutline size={20} className="icons" />
              {t("aboutUs")}
            </Link>
          </div>
          <div className="links" >
            <Link to={`/${config.ddemoss}category`} className={`links res-routes ${location.pathname === "/category" ? 'Activeroute' : ''}`} >
              <TbCategory size={20} className="icons" />
              {t("category")}
            </Link>
          </div>
          <div className="links" >
            <Link to={`/${config.ddemoss}contact`} className={`links res-routes ${location.pathname === "/contact" ? 'Activeroute' : ''}`}>
              <MdPhoneInTalk size={20} className="icons" />
              {t("contactus")}
            </Link>
          </div>
          {UserCredential?.id &&
            <div className="links" >
              <Link to={`/${config.ddemoss}profile`} className={`links res-routes ${location.pathname === "/profile" ? 'Activeroute' : ''}`}>
                <CgProfile size={20} className="icons" />
                {t("profile")}
              </Link>
            </div>
          }
          {UserCredential?.type == "merchant" &&
            <div className="links" >
              <Link to={`/${config.ddemoss}addproduct`} className={`links  res-routes ${location.pathname === "/addproduct" ? 'Activeroute' : ''}`}>
                <IoBagAddOutline size={20} className="icons" />
                {t("addProduct")}
              </Link>
            </div>
          }
          {UserCredential?.id ? (
            <div className="links" onClick={logOut}>
              <Link to={`/${config.ddemoss}logout`} className={`links res-routes ${location.pathname === "/logout" ? 'Activeroute' : ''}`}>
                <MdLogout size={20} className="icons" />
                {t("logout")}
              </Link>
            </div>
          ) :
            <>
              <div className="links" >
                <Link to={`/${config.ddemoss}login`} className={`links res-routes ${location.pathname === "/login" ? 'Activeroute' : ''}`} variant="contained" size="medium">
                  <MdLogin className="icons" />
                  {t("login")}
                </Link>
              </div>
              <div className="links" >
                <Link to={`/${config.ddemoss}signup`} className={`links res-routes ${location.pathname === "/signup" ? 'Activeroute' : ''}`} variant="contained" size="medium">
                  <CgProfile className="icons" />
                  {t("signup")}
                </Link>
              </div>
            </>
          }
        </motion.div>
      </Drawer>
    </>
  );
}


export default DrawerAppBar;
