import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "./App.css"
import "./HM.css"
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { initReactI18next } from "react-i18next";
import i18n from './Languages/Language';
import UserContext from './Context/UserContext';
import ContentText from './Context/ContentText';
// import { GoogleOAuthProvider } from '@react-oauth/google';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LanguageContext from './Context/LangaugeContext';

// import { FacebookProvider } from 'react-facebook';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <initReactI18next i18n={i18n}>
      {/* <GoogleOAuthProvider clientId="531320436674-3r8r3rg011ej7gm1lot525mqvppg1k0s.apps.googleusercontent.com">
        <FacebookProvider appId="7078684935554358"> */}
      <LanguageContext>
        <UserContext>
          <ContentText>
            <BrowserRouter >
              <App />
            </BrowserRouter>
          </ContentText>
        </UserContext>
      </LanguageContext>
      {/* </FacebookProvider>
      </GoogleOAuthProvider> */}
    </initReactI18next>
  </React.StrictMode >
);


reportWebVitals();
