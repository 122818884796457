import { useState, useEffect, useContext } from 'react'
import favimg from '../Assets/WebImages/AboutUs.png'
import Star from '../Assets/WebImages/star.jpg'
import { FaRegHeart } from "react-icons/fa";
import { MainUsersContext } from "../Context/UserContext";
import { getTokenSession } from '../MainFunction';


import config from "../Services/Config.json"
import api from "../Services/httpService";
import { useTranslation } from 'react-i18next';

const calculateAverageRating = (ratings) => {
  if (ratings.length === 0) return 0;
  const total = ratings.reduce((sum, rating) => sum + rating.rating, 0);
  return Math.floor(total / ratings.length);
};

const renderStars = (rating) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(<img key={i} src={Star} alt="filled star" style={{ width: '20px', height: '20px' }} />);
    }
  }
  return stars;
};

const MyFavourite = () => {
  const { t } = useTranslation();
  const [favoriteUsers, setFavoriteUsers] = useState([]);

  useEffect(() => {
    const getFavoriteUsers = async () => {
      
      const data = await api.get(
        `${config.apiEndPoint}wishlists`,
        {
            headers: {
                Authorization: `Bearer ${getTokenSession()}`
            }
        }
      );

      setFavoriteUsers(data.data.data);
    };

    getFavoriteUsers()
  }, []);

  const handleUnfavorite = (userId) => {
    // console.log(userId);
    api.post(
      `${config.apiEndPoint}wishlists`,
      { vendor_id: userId, is_like: 0 },
      {
          headers: {
              Authorization: `Bearer ${getTokenSession()}`
          }
      }
    );

    setFavoriteUsers((prevData) => {
      return prevData.filter((user) => {
        return user.vendor.id !== userId;
      });
    });

  };

  
  return (
    <div className='mainFavourite'>
      <div className='Favourite-div'>
        <h3>{t("My Favourite")}</h3>

          {favoriteUsers.length !== 0 ? (
            favoriteUsers.map((favoriteUser, index) => {
              const averageRating = calculateAverageRating(favoriteUser.vendor.ratings);
              return (
                <div key={index} className='pt-2' style={{ marginRight:"20px" }}>
                  <div className="favlist p-10 d-flex align-items-center justify-content-between">
                      <div className="image d-flex py-3 align-items-center">
                          <img src={favoriteUser.vendor.image} alt="" className='favimg' />
                          <div className="favtext ms-2">
                              <h6 >{favoriteUser.vendor.name}</h6>
                              <div className="star d-flex">
                              {t("Rating")}: {renderStars(averageRating)}
                              </div>
                          </div>
                      </div>
                      <div className="heartimage" style={{ cursor: 'pointer' }} onClick={() => handleUnfavorite(favoriteUser.vendor.id)} >
                        <FaRegHeart color='white'/>
                      </div>
                  </div>
                </div>
              )

            })
          ) : (
            <>
            </>
          )}


      </div>
    </div>
  )
}

export default MyFavourite
