import { Button } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowForward } from "react-icons/io";
import OtpInput from 'react-otp-input';
import config from "../Services/Config.json"
import { toast } from 'react-hot-toast';
import { useNavigate } from "react-router";
import { setIdSession, setTokenSession, } from "../MainFunction";
import { MainUsersContext } from "../Context/UserContext";

function CardsTwo({ tittle }) {
    const navigate = useNavigate();
    const { HandleUserCredential } = useContext(MainUsersContext);
    const { t } = useTranslation();
    const [otp, setOtp] = useState('');
    const [Loading, setLoading] = useState(false);
    const Email = localStorage.getItem("email")

    useEffect(() => {
        if (!Email || Email == null || Email == "") {
            setTimeout(() => {
                navigate(`/${config.ddemoss}login`)
            }, 2000);
        }
    }, [Email]);

    function HAndleOTP() {
        if ((Email != "" && Email != null && Email != "undefined") && (otp != "")) {
            setLoading(true)
            let formdata = new FormData();
            formdata.append("email", Email);
            formdata.append("otp", otp);
            axios.defaults.headers = {
                "Content-Type": "multipart/form-data",
            };
            axios.post(`${config.apiEndPoint}verify-otp`, formdata)
                .then((response) => {
                    if (response) {
                        setLoading(false)
                        if ((response?.data?.status && response?.data?.message == "OTP verified successfully" && response?.data?.status_code == 200)) {
                            localStorage.removeItem("email");
                            setIdSession(response?.data?.data?.user?.id)
                            setTokenSession(response?.data?.data?.token)
                            toast.success(t('Account Verification successfully!'), {
                                icon: '👏',
                                duration: 5000,
                            });
                            setTimeout(() => {
                                navigate(`${config.ddemoss}/accountcreate`)
                            }, 1000);
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    toast.error(t(error?.response?.data?.message), {
                        icon: '❌',
                        duration: 5000,
                        style: {
                            background: '#FF822D',
                            color: "#fff",
                            minWidth: 200
                        },
                    }
                    );
                });
        }
    }
    const HandleReSendOTP = () => {
        let formdata = new FormData();
        if (Email && Email != "" && Email != null && Email != "undefined") {
            setLoading(true)
            formdata.append("email", Email);
            axios.defaults.headers = {
                "Content-Type": "multipart/form-data",
            };
            axios.post(`${config.apiEndPoint}resend-otp`, formdata)
                .then((response) => {
                    if (response) {
                        setLoading(false)
                        toast.success(t(response?.data?.message), {
                            icon: '📬',
                            duration: 5000,
                            style: {
                                background: '#75c936',
                                color: "#fff",
                                minWidth: 200
                            },
                        }
                        );
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    toast.error(error?.response?.data?.message
                        , {
                            icon: '❌',
                            duration: 5000,
                            style: {
                                background: '#FF822D',
                                color: "#fff",
                                minWidth: 200
                            },
                        }
                    );
                });
        }
    }


    return (
        <div className='cardtwomain my-5'>
            {tittle == "accountcreate" ?
                <>
                    <div className="my-3">
                        <p style={{ fontSize: "14px", fontFamily: "Poppins", fontWeight: 500 }}>
                            {t("AccountCreateSuccessfully")}
                        </p>
                    </div>
                    <div className="accountimg">
                        <img src={require("../Assets/WebImages/accountcreate.png")} alt="" />
                    </div>
                    <div className="my-3">
                        <p style={{ fontSize: "12px", fontFamily: "Poppins", fontWeight: 500 }}>
                            {t(`YourAccounthasbeen`)}
                            {t(`CreateSuccessfully`)}
                        </p>
                    </div>
                    <div className="acountbtn">
                        <Button onClick={() => navigate(`${config.ddemoss}/`)} color="secondary" size="large" variant="filled" >
                            <IoIosArrowForward />
                        </Button>
                    </div>

                </>

                :
                <>
                    <div className='veri-image'>
                        <img src={require("../Assets/WebImages/verificationpng.png")} alt="" />
                    </div>
                    <div className="text-center my-4">
                        <span style={{ fontSize: "12px", fontFamily: "Poppins", }}>
                            {t("Pleaseentertheverificationcode")}<br />
                            {t("wesendtoyouremail")}
                        </span>
                    </div>
                    <div className="d-flex align-items-center mb-3 num_verification">
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            inputType={"number"}
                            className="num_veri"
                            renderSeparator={<span>&nbsp; &nbsp;</span>}
                            renderInput={(props) => <input className="num_veri" {...props}
                            />}
                        />
                    </div>
                    <div>
                        <span style={{ color: " #9A9A9A", fontSize: "15px" }}>
                            {t("Dontreceiveacode")}  <span className={Loading && "disabled-span"} onClick={HandleReSendOTP} style={{ color: " #2B9229", cursor: "pointer" }}>{t("Resend")}</span>
                        </span>
                    </div>
                    <div className="veribtn mt-3">
                        <Button disabled={Loading} className='veribtncnf' onClick={HAndleOTP} variant="contained" color="success" >
                            {t("Confirm")}
                        </Button>
                    </div>
                </>
            }
        </div>
    );
}

export default CardsTwo;