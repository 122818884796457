import { Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from "../Services/Config.json"
import { languageContextProvider } from "../Context/LangaugeContext";
import axios from "axios";
import HomeSlider2 from "./Homeslider2";
// import "../App.css"

gsap.registerPlugin(ScrollTrigger);


function AppBanner({ data }) {
  const { handleLanguageChange_Context, langName } = useContext(languageContextProvider);
  const [homeImage,sethomeImage]=useState()
  const [ContentView, setContentView] = useState();

  useEffect(() => {
    if (data) {
      if (langName == "arabic") {
        setContentView(data?.data?.arabic?.banner_content)
      } else {
        setContentView(data?.data?.banner_content)
      }
    }
  }, [data, langName]);

  const navigate = useNavigate()
  const { t } = useTranslation();

  useEffect(() => {
    gsap.set('.home_webbanner_text', { opacity: 0, y: 100 });

    ScrollTrigger.create({
      trigger: '.home_webbanner_text',
      start: 'top 80%',
      onEnter: () => {
        gsap.to('.home_webbanner_text', {
          opacity: 1,
          y: 0,
          duration: 0.9,
          delay: 1,
          ease: 'power1.out',
        });
      },
      once: true,
    });
  }, []);

  useEffect(() => {
    async function fetchImage() {
      try {
        const response = await axios.get(`${config.apiEndPoint}slider`);
        // console.log(response?.data?.data?.slider);
        sethomeImage(response?.data?.data?.slider)
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }
  
    fetchImage();
  }, []);
  
  return (
    <div className="backgroundImage">
     <HomeSlider2 data={homeImage}/>
      <div className="container home_webbanner_banner">
        <div className="home_webbanner_text">
          <div dangerouslySetInnerHTML={{ __html: ContentView && ContentView }} />
        </div >
        {/* <h6 >WHAT IS LOREM? </h6>
          <h1>WHAT IS LOREM IPSUM?</h1>
          <h1>
            SIMPLY <span className="text-success"> DUMMY TEXT </span>
          </h1>
          <div>
            <p style={{ marginBottom: "0", fontWeight: 500 }}>
              LOREM ISPSUM I SSIMPLY DUMMY TEXT OF THE PRINTING AND TYPESETTING
              INDUSTRY.{" "}
            </p>
            <p style={{ fontWeight: 500 }}>
              LOREM ISPSUM JHAS BEEN THE INDUSTRY'S STANDAR DUMMY TEXT OVER SINCE
              THE 1500s,
            </p>
          </div> */}
        {/* </motion.div> */}
        <Button className="mb-2 mt-3 btn" onClick={() => navigate(`/${config.ddemoss}aboutus`)} variant="h6">
          {t("ReadMore")}
        </Button>
      </div>
    </div>
  );
}

export default AppBanner;
