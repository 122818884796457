import React, { useEffect } from "react";
import demouser from "../../Assets/WebImages/demouser.png";
import { FaStar } from "react-icons/fa6";
import Aboutme from "./Aboutme";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import aos from 'aos';
import "aos/dist/aos.css"
import RatingStar from "../Rating/RatingStars";
function PeopleComment({ RecommendedData_comments }) {

  const { t } = useTranslation()

  // const data = [
  //   {
  //     id: 1,
  //     img: Commet1,
  //     name: "Jacod",
  //     days: "02 Days Ago",
  //     para: `Dana Makes The Good. Pizza And very Delicuos! Great Value For Money. Only DownSide is the Right Delivery Time.`,
  //   },
  //   {
  //     id: 2,
  //     img: Commet2,
  //     name: "Or",
  //     days: "05 Days Ago",
  //     para: `Dana has the great selection of Deserts, through a bit overpriced in my opinion`,
  //   },
  //   {
  //     id: 3,
  //     img: Commet3,
  //     name: "Daniel",
  //     days: "10 Days Ago",
  //     para: `Yesterday my freind and i had dinner  at Dana's house, she such a gracious host. `,
  //   },
  // ];


  return (
    <div className="PeopleComment mt-5 sm-mt-3">
      <div className="backGround_PpleComt">
        <h4>{t("Theyaresaying")}</h4>
        <div className="row justify-content-center align-items-center mt-5 ">
          {/* {data.map((x, i) => (
            <div
              key={i}
              className="col-lg-4 col-md-12 col-xs-12 pt-4 Comment"
              style={{ borderRight: i === 2 ? "none" : "2px solid #418E30" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={x.img} alt="" style={{ marginRight: "12px" }} />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h6>{x.name}</h6>
                  <h4
                    style={{
                      display: "block",
                      marginTop: "-5px",
                      marginLeft: "-8px",
                      fontSize: "10px"
                    }}
                  >
                    {starsArray.map((_, index) => (
                      <FaStar key={index} color="#ffc107" className="ms-1" />
                    ))}
                  </h4>
                </div>
                <div className="dcaafafa">
                  <p className="mt-2  float-end days"
                  >
                    {x.days}
                  </p>
                </div>
              </div>
              <p className="parag" style={{ color: "#423F55" }}>
                {x.para}
              </p>
            </div>
          ))} */}

          {Array.isArray(RecommendedData_comments?.data?.review_services) && RecommendedData_comments?.data?.review_services.map((item, index) => {
            return (
              <div className="col-md-4 col-sm-12 ReviewEach" key={index}
                style={{ borderRight: index < 2 && "2px solid #418E30" }}
              >
                <div className="reviewcard_header">
                  <div className="reviewuser_info">
                    <img src={item?.user?.image && item?.user?.image} alt="" width={50} height={50} style={{ borderRadius: 100,objectFit:"cover" }} />
                    <div>
                      <div className="reviewuser_name">
                        <span>{item?.user?.name.slice(0, 10)}</span>
                      </div>
                      <div className="reviewuser_star">
                        <RatingStar Star={item?.rating} recommended={true} />
                      </div>
                    </div>
                  </div>
                  <div className="reviewdate">
                    <span>
                      {item?.created_at}
                    </span>
                  </div>
                </div>
                <div className="review_para mt-2">
                  <p>
                    {item?.review_detail}
                  </p>
                </div>
              </div>
            )
          })
          }
          {/* <div className="text-center mt-5">
            <Button className="mb-2 mt-3 button" variant="h6" >
              {t("viewmore")}
            </Button>
          </div> */}
        </div>
      </div>
    </div >
  );
}

export default PeopleComment;
