import React from 'react'
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion"

const AllBanners = ({ title }) => {
    const { t } = useTranslation();

    // console.log(title);
    return (
        <div className='allbanners_main'>
            <div className='banner_inner_text'>
                <motion.h2 initial={{opacity: 0, y: 30}} animate={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
                    {t(title)}
                </motion.h2>
                <div className='line'></div>
                {title === "Login" &&
                    <>
                        < div >
                            <h3>
                                {t("WelcomeBack")}
                            </h3>
                        </div>
                        <div>
                            <p>
                                {t("signintoaccessyouraccount")}
                            </p>
                        </div>
                    </>
                }
                {
                    title === "Register" || title === "يسجل" ?(

                    <div>
                        <p>
                            {t("signupyouraccount")}
                        </p>
                    </div>
                    ) :""
                }
            </div>
        </div >
    )
}

export default AllBanners