import { useEffect, useState, useRef } from "react";
// import http from "../services/httpServices";
import config from "../Services/Config.json";
import { getTokenSession } from "../MainFunction";
import httpService from "../Services/httpService";
// import { getTokenSession, removeTokenSession } from "../utils/common";
const useFetch = (url) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const authentication = getTokenSession();
    const isComponentMounted = useRef(true);

    useEffect(() => {
        if (isComponentMounted.current) {
            getData();
        }
        return () => {
            isComponentMounted.current = false;
            setData(null);
            setLoading(true);
            setError("");
        };
    }, []);

    const getData = async () => {
        if (url) {
            try {
                const { data } = await httpService.get(`${config.apiEndPoint}${url}`, {
                    headers: {
                        Authorization: `Bearer ${authentication}`,
                    },
                });
                if (
                    data.success === 0 &&
                    data.status === 401 &&
                    (data.message === "Unauthorized Token" || data.message === "Invalid Password!")
                ) {
                    // removeTokenSession();
                    // navigate(`/${config.ddemoss}login`);
                }
                setData(data);
                setLoading(false);
            } catch (e) {
                setLoading(true);
                setError(e);
            }
        }
    };

    return {
        loading,
        data,
        error,
    };
};
export default useFetch;
