import React, { useContext, useEffect, useState } from "react";
import WhyChosseus from "../Assets/WebImages/WhyChosseus.png";
import WhyChosseus1 from "../Assets/WebImages/WhyChosseus1.png";
import WhyChosseus2 from "../Assets/WebImages/WhyChosseus2.png";
import WhyChosseus3 from "../Assets/WebImages/WhyChosseus3.png";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion"
import { languageContextProvider } from "../Context/LangaugeContext";

function WhyChooseUs({ data }) {

  const { handleLanguageChange_Context, langName } = useContext(languageContextProvider);



  const list = [
    {
      id: 1,
      listImage: WhyChosseus1,
      listheading: langName == "arabic" ? data?.data?.arabic?.mid_content_list1 : data?.data?.mid_content_list1,

    },
    {
      id: 2,
      listImage: WhyChosseus2,
      listheading: langName == "arabic" ? data?.data?.arabic?.mid_content_list2 : data?.data?.mid_content_list2,

    },
    {
      id: 2,
      listImage: WhyChosseus3,
      listheading: langName == "arabic" ? data?.data?.arabic?.mid_content_list3 : data?.data?.mid_content_list3,
    },
  ];

  const { t } = useTranslation();
  return (

    <motion.div className="ChooseUS" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
      <div className="container py-5">
        <div className="row justify-content-center align-items-center ">
          <motion.div className="col-lg-6 col-md-6 col-xs-12  whychoosebanner_content " initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.2 }} >
            <div className="whychoosebanner_border">
              <div className="whychoosebanner_img">
                <img
                  src={WhyChosseus}
                  alt="Why Chosse Us"
                  width="100%"
                />
              </div>
            </div>

          </motion.div>
          <motion.div className="col-lg-6 col-md-6 col-xs-12 " initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.2 }}>
            <div className="chooseUS_div">
              <h2>{langName == "arabic" ? data?.data?.arabic?.mid_content_title : data?.data?.mid_content_title}</h2>
              < h6 dangerouslySetInnerHTML={{ __html: langName == "arabic" ? data?.data?.arabic?.mid_content_desc : data?.data?.mid_content_desc }} />
            </div>
            <div className="imgandtext mt-3">
              {list?.map((item, i) => (
                <React.Fragment key={i}>
                  <motion.div className="list_View mt-2" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: i * 0.2 }}>
                    <div className="ChooseUsImg_BackGround">
                      <div className="Chooseus_ImageWrapper">
                        <img src={item.listImage} alt="Image Alt Text" />
                      </div>
                    </div>
                    <div className="whychoosecontent_text" dangerouslySetInnerHTML={{ __html: item?.listheading }} />
                    {/* <h4 >{item.listheading}</h4>
                      <p style={{ marginBottom: 0 }}>
                        {item.listpara1}{" "}
                      </p>
                      <p > {item.listpara2}</p> */}
                  </motion.div>
                </React.Fragment>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>



  );



}

export default WhyChooseUs;
