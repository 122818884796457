import react from "react";
import Slider from "react-slick";

function HomeSlider2({ data }) {
  // console.log(data);

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <div className="h-100">
      <Slider {...settings}>
        {data &&
          data?.map((obj, index) => {
            return <img src={obj?.media_link} key={index} alt="banner" className="h-100" />;
          })}
      </Slider>
    </div>
  );
}

export default HomeSlider2;
