import { FloatButton } from "antd";
import AppRouter from "./Routes/Routes";
import { Toaster } from 'react-hot-toast';


function App() {
  return (
    <>
      <AppRouter />
      <Toaster position="top-right" />
      <FloatButton.BackTop />
    </>
  );
}

export default App;
