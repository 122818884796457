import PlaceIcon from "@mui/icons-material/Place";
import { FaPhone } from "react-icons/fa6";
import { ThemeProvider, createTheme } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { languageContextProvider } from "../Context/LangaugeContext";
import { mainContentTextProdvider } from "../Context/ContentText";
const provinceData = ["English", "Arabic"];

function TopHeader() {
  const { i18n } = useTranslation();
  const { handleLanguageChange_Context, langName } = useContext(languageContextProvider);
  const { Content } = useContext(mainContentTextProdvider);




  const handleChangeLang = (value) => {
    // if (value === "Arabic") {
    //   i18n.changeLanguage("arabic");
    //   localStorage.setItem("language", "arabic");
    // } else {
    //   i18n.changeLanguage("english");
    //   localStorage.setItem("language", "english");
    // }
    handleLanguageChange_Context(value)
  };
  const storedLanguage = localStorage.getItem("language");

  useEffect(() => {
    i18n.changeLanguage(storedLanguage);
  }, []);

  return (
    <div className="top-header-app">
      <div className="top-header-main">
        <div className="top-box-select">
          {storedLanguage === "arabic" ? (
            <img
              src={require("../Assets/WebImages/arabic.jpeg")}
              style={{ borderRadius: "23px", width: "20px", objectFit: "cover" }}
            />
          ) : (
            <img src={require("../Assets/WebImages/Component.png")} style={{ borderRadius: "23px", width: "20px", objectFit: "cover" }} alt="" />
          )}
          <Select
            defaultValue={storedLanguage || provinceData[0]}
            style={{
              width: 120,
            }}
            className="Custom-top-header-select"
            onChange={handleChangeLang}
            value={langName && langName == "arabic" ? "Arabic" : "English"}
            options={provinceData.map((province) => ({
              label: province,
              value: province,
            }))}
          />
        </div>
        <div className="border-0 hdt">
          <FaPhone /> {Content?.phone}
        </div>
      </div>
    </div>
  );
}
export default TopHeader;
