import { useContext, useEffect } from "react";
import AllBanners from "../Components/AllBanners";
import Allcards from "../Components/Allcards";
import Footer from "../Components/Footer";
import TopHeader from "../Components/TopHeader";
import { useNavigate } from "react-router-dom";
// import DrawerAppBar from "../Components/AppHeader";
import config from "../Services/Config.json"
import { MainUsersContext } from '../Context/UserContext'

function IndetifyPass() {

    const { UserCredential } = useContext(MainUsersContext);
    const navigate = useNavigate()

    useEffect(() => {
        if (UserCredential?.id) {
            navigate(`/${config.ddemoss}`);
        }
    }, [UserCredential]);
    return (
        <div>
            <TopHeader />
            {/* <DrawerAppBarr /> */}
            <AllBanners title={"New Password"} />
            <div className='Card_container'>
                <Allcards forpage={"VerifyOTP"} />
            </div>
            <Footer />
        </div>
    );
}

export default IndetifyPass;