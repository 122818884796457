import React ,{useState,useEffect}from "react";
import AppLoader from "../Components/AppLoader";
import LeftSideBar from '../Components/Profile/LeftSideBar'
import TopHeader from '../Components/TopHeader'
import AppHeader from "../Components/AppHeader";
import AllBanners from '../Components/AllBanners';
import { NavLink } from 'react-router-dom';
import MyFavourite from '../Components/Myfavourite';

const Favourite = () => {
  const [Isloader,setIsLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  }, [Isloader]);
    return (
      <div>
        <AppLoader Isloader={Isloader} />
          <TopHeader />
          <AppHeader />
          <AllBanners title={"Favourite"} />
          <div className='flex flex-direction-column'>
        <LeftSideBar/>
            <MyFavourite/>
        
        </div>
        
      </div>
    )
  }
  
  export default Favourite
  