import React ,{useState,useEffect, useContext}from "react";
import LeftSideBar from "../Components/Profile/LeftSideBar";
import TopHeader from "../Components/TopHeader";
import AppHeader from "../Components/AppHeader";
import AllBanners from "../Components/AllBanners";
import ChangeEmailCom from "../Components/ChangeEmailCom";
import Footer from "../Components/Footer";
import AppLoader from "../Components/AppLoader";
import { MainUsersContext } from '../Context/UserContext'
const ChangeEmail = () => {
  const { UserCredential } = useContext(MainUsersContext);
  // console.log(UserCredential);
  const [Isloader,setIsLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  }, [Isloader]);
  return (
    <div>
      <AppLoader Isloader={Isloader} />
      <TopHeader />
      <AppHeader />
      <AllBanners title={"Change Email"} />
      <div className="flex flex-direction-column">
        <LeftSideBar />

        <ChangeEmailCom data={UserCredential}/>
      </div>
      <Footer />
    </div>
  );
};

export default ChangeEmail;
