import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


export const languageContextProvider = createContext();

const LanguageContext = ({ children }) => {
  const [langName, setLangName] = useState(localStorage.getItem("language"));
  const { i18n } = useTranslation();

  const handleLanguageChange_Context = (value) => {
    if (value === "Arabic") {
      i18n.changeLanguage("arabic")
      localStorage.setItem("language", "arabic");
      setLangName("arabic");
    } else {
      i18n.changeLanguage("english")
      localStorage.setItem("language", "english");
      setLangName("english");
    }
  };



  return (
    <languageContextProvider.Provider
      value={{ langName, handleLanguageChange_Context }}
    >
      {children}
    </languageContextProvider.Provider>
  );
};
export default LanguageContext;


// import { createContext, useEffect, useState } from "react";
// export const mainContentTextProdvider = createContext();
// function ContentText({ children }) {
//   const [Content, setContent] = useState();

//   return (
//     <mainContentTextProdvider.Provider value={{ setContent, Content }}>
//       {children}
//     </mainContentTextProdvider.Provider>

//   );
// }

// export default ContentText;