import { useState, useEffect } from "react";
import DeleteImage from "../Assets/WebImages/Delete.png"

import config from "../Services/Config.json"
import api from "../Services/httpService";
import { getTokenSession } from '../MainFunction';
import { useTranslation } from "react-i18next";


const NotificationCom = () => {

  const { t } = useTranslation();

  const [notificationsData, setNotificationsData] = useState([]);

  useEffect(() => {
    
    fetchNotifications('?new=1');
    
  }, []);

  const fetchNotifications = async (parameter='') => {
    let apiUrl = `${config.apiEndPoint}get-notifications${parameter}`;

    // console.log(apiUrl);

    const apiResponse = await api.get(
      apiUrl,
      {
        headers: {
            Authorization: `Bearer ${getTokenSession()}`
        }
      }
    );

    const { data } = apiResponse.data.data;

    setNotificationsData(data);
  };

  const handleNotificationDelete = async (notificationId) => {
    const apiResponse = await api.get(
      `${config.apiEndPoint}delete-notification/${notificationId}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenSession()}`
        }
      }
    );

    const { data } = apiResponse;

    if (data.data.success) {
      setNotificationsData(prevData =>
        prevData.filter(notification => notification.id !== notificationId)
      );
    }
  };

  const handleNew = () => {
    fetchNotifications('?new=1');
  };

  const handleAll = () => {
    fetchNotifications();
  }

  return (
    <div className="NotificationCom">
      <div className="ChangeEmailCom-div">
        <h3>{t("Notification")}</h3>
        <div className="div-input-email-pagen green-color">
          {/* <p>On/off</p> */}
        </div>
        <div className="div-input-email-page">
          <div className="Notification-Buttons">
            <button onClick={handleNew} className="button-1">{t("New")}</button>
            <button onClick={handleAll} className="button-2">{t("All")}</button>
          </div>
        </div>
        {notificationsData.length > 0 ? (
          notificationsData?.map((notification, index) => (
            <div key={index} className="div-input-email-page Notification-Lorem flex justify-content-space-between">
              <p>
                {notification.message}
              </p>
              <div className="time-Notification">
                <p>{notification.created_at.created_at}</p>
                <img
                  style={{ position: "absolute", top: "0 !important", cursor: 'pointer' }}
                  src={DeleteImage} alt="Delete Icon"
                  onClick={() => handleNotificationDelete(notification.id)}
                />
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default NotificationCom;