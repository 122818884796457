import React ,{useState,useEffect, useContext}from "react";
import LeftSideBar from '../Components/Profile/LeftSideBar'
import TopHeader from '../Components/TopHeader'
import AppHeader from "../Components/AppHeader";
import ChangePasswordCom from '../Components/ChangePasswordCom';
import AllBanners from '../Components/AllBanners';
import Footer from "../Components/Footer";
import AppLoader from "../Components/AppLoader";
import { MainUsersContext } from '../Context/UserContext'
const ChangePassword = () => {
  const [Isloader,setIsLoader] = useState(true)
  const { UserCredential } = useContext(MainUsersContext);
  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  }, [Isloader]);
  return (
    <div>
       <AppLoader Isloader={Isloader} />
        <TopHeader />
        <AppHeader />
        <AllBanners title={"Change Password"} />
        <div className='flex flex-direction-column'>
      <LeftSideBar/>
      <ChangePasswordCom data={UserCredential}/>
      </div>
      <Footer/>

    </div>
  )
}

export default ChangePassword
