

export const setEmailSession = (email) => {
    localStorage.setItem("email", email);
};
export const setIdSession = (authid) => {
    localStorage.setItem("authid", authid);
};

export const getIdSession = () => {
    return localStorage.getItem("authid") || null;
};

export const setTokenSession = (token) => {
    localStorage.setItem("token", token);
};
export const getTokenSession = () => {
    return localStorage.getItem("token") || null;
};

export const ClearTokenSession = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("authid");
    localStorage.removeItem("email");
};

export const HandleLoginUser_SetLocalStorage = (userobj) => {
    localStorage.setItem("name", userobj?.name)
    localStorage.setItem("type", userobj?.type)
    localStorage.setItem("authid", userobj?.authid)
    localStorage.setItem("country", userobj?.country)
    localStorage.setItem("phone_number", userobj?.phone_number)
    localStorage.setItem("token", userobj?.token)
    localStorage.setItem("email", userobj?.email)
    localStorage.setItem("profile", userobj?.profile)

};
export const HandleLoginUser_GetLocalStorage = () => {
    const userDetails = {
        name: localStorage.getItem("name"),
        type: localStorage.getItem("type"),
        authid: parseInt(localStorage.getItem("authid")),
        country: localStorage.getItem("country"),
        phone_number: localStorage.getItem("phone_number"),
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email")
    };

    return userDetails;
};