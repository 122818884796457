import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../CustomHooks/UseFetch";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../Services/Config.json"

function ProductCom2({ recommandedData }) {
    const { t } = useTranslation();
    const { data: recommended, loading, error } = useFetch("services/recommended");
    const [arr, setArr] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        if (recommended) {
            setArr([...recommended.data])
        }
    }, [recommended]);

    return (
        <>
            <div className="line">
                <h3 className="text-center">
                    {t("recommended service")}
                </h3>
            </div>
            <div className="w-100 d-flex prd2each_main flex-wrap gap-3 justify-content-center">
                {arr && Array.isArray(arr) && arr.slice(0, 4).map((item, i) => {
                    return (
                        <>
                            <div key={i} className="prd2each" onClick={() => {
                                window.scrollTo(0, 0);
                                navigate(`/${config.ddemoss}buyproduct/${item?.id}`)
                            }}>
                                <div className="productcard2img">
                                    <img src={item.media_link} alt="" />
                                </div>
                                <span>
                                    {(item?.name && item?.name?.length > 15
                                        ? item?.name.slice(0, 10) + "..."
                                        : item?.name)}
                                </span>
                            </div>
                        </>
                    )
                })}
            </div>
        </>
    );
}

export default ProductCom2;