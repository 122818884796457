import TopHeader from "../../Components/TopHeader";
// import AppHeader from "../../Components/AppHeader";
import AppBanner from "../../Components/AppBanner";
import AppBannerTwo from "../../Components/AppBannerTwo";
import CategoriesBanner from "../../Components/CategoriesBanner";
import WhyChooseUs from "../../Components/WhyChooseUs";
import FeatureProviderBanner from "../../Components/FeatureProviderBanner";
import Footer from "../../Components/Footer";
import { useEffect, useState } from "react";
import DrawerAppBar from "../../Components/AppHeader";
import useFetch from "../../CustomHooks/UseFetch";
import NewArrival from "../../Components/NewArrival";
import AppLoader from "../../Components/AppLoader";
import Advertisement from "../../Components/Advertisement";

function Home() {
  const { data: HomeContent, loading, error } = useFetch("home-content");
  const { data: categories_home, loading1, error2 } = useFetch("categories_home");
  const [Isloading, setIsloading] = useState(true);
  console.log(HomeContent);
  useEffect(() => {
    setIsloading(loading)
  }, [loading]);

  return (
    <>
      <AppLoader Isloader={Isloading} />
      <div className="main_Home">
        <TopHeader />
        <DrawerAppBar theme={"#FFFFFF"} />
        <AppBanner data={HomeContent && HomeContent} />
        <CategoriesBanner data={categories_home && categories_home} />
        {/* <AppBannerTwo /> */}

        {/* comment by client req  */}
        {/* <NewArrival />   */}

        <Advertisement />



        <WhyChooseUs data={HomeContent && HomeContent} />
        <FeatureProviderBanner data={HomeContent && HomeContent} />
        <Footer />
      </div>
    </>
  );
}

export default Home;
