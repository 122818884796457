import { useContext, useEffect } from "react";
import AllBanners from "../Components/AllBanners";
import DrawerAppBar from "../Components/AppHeader";
import CardsTwo from "../Components/CardsTwo";
import Footer from "../Components/Footer";
import TopHeader from "../Components/TopHeader";

import { useNavigate } from "react-router-dom";
import { MainUsersContext } from "../Context/UserContext";
import config from "../Services/Config.json"

function Verification() {
    const { UserCredential } = useContext(MainUsersContext);
    const navigate = useNavigate()

    useEffect(() => {
        if (UserCredential?.id) {
            navigate(`/${config.ddemoss}`);
        }
    }, [UserCredential]);

    return (
        <div>
            <TopHeader />
            <DrawerAppBar />
            <AllBanners title={"Verification Code"} />
            <div className='Card_container' >
                <CardsTwo />
            </div>
            <Footer />
        </div>
    );
}

export default Verification;