import React, { useContext, useEffect, useState } from "react";
import TopHeader from "../TopHeader";
import AppHeader from "../AppHeader";
import RecommendedBanner from "./RecommendedBanner";
import Footer from "../Footer";
import share from "../../Assets/WebImages/share.png";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../CustomHooks/UseFetch";
import config from "../../Services/Config.json"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import badgeimg from "../../Assets/WebImages/badgeimage.png";
// import { useTranslation } from 'react-i18next'
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon
} from 'react-share';
import RatingStar from "../Rating/RatingStars";
import { MainUsersContext } from "../../Context/UserContext";
import VerifyUserForShare from "./VerifyUserForShare";
import AppLoader from "../AppLoader";
import axios from "axios";
import { getTokenSession } from "../../MainFunction";
import { toast } from "react-hot-toast";

gsap.registerPlugin(ScrollTrigger);


function BuyProduct() {
  const [togle,setistogle]=useState(true)
  const { id } = useParams()
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { UserCredential } = useContext(MainUsersContext);
  const authentication = getTokenSession();

  const { data: servicesData, loading, error } = useFetch(`services/show/${id}`);
  const [Sharedropdown, setSharedropdown] = useState(false);
  const [SeriveAllData, setSeriveAllData] = useState();
  const [Isloading, setIsloading] = useState(true);
  const [GetRecommandedIdData, setGetRecommandedIdData] = useState(0);


  const [MerchantMedia, setMerchantMedia] = useState({
    whatsapp: "",
    instagram: ""
  });
  const [MediaLink, setMediaLink] = useState({
    url: "",
    title: ""
  });

  useEffect(() => {
    if (servicesData?.data) {
      // console.log(servicesData);
      setSeriveAllData(servicesData)
      setMerchantMedia({
        whatsapp: servicesData?.data?.service?.user?.whatsapp,
        instagram: servicesData?.data?.service?.user?.instagram,
        id: servicesData?.data?.service?.user?.id
      })
      setMediaLink({
        url: `${config.url}buyproduct/${id}`,
        title: servicesData?.data?.service?.name,
        description: servicesData?.data?.service?.description
      })
    }

  }, [servicesData]);

  useEffect(() => {
    if (GetRecommandedIdData == 1) {
      setIsloading(true)
      axios.get(`${config.apiEndPoint}services/show/${id}`, {
        headers: {
          Authorization: `Bearer ${authentication}`,
        },
      })
        .then((servicesData) => {
          if (servicesData?.data?.data) {
            setSeriveAllData(servicesData?.data)
            setMerchantMedia({
              whatsapp: servicesData?.data?.data?.service?.user?.whatsapp,
              instagram: servicesData?.data?.data?.service?.user?.instagram
            })
            setMediaLink({
              url: `${config.url}buyproduct/${id}`,
              title: servicesData?.data?.data?.service?.name,
              description: servicesData?.data?.data?.service?.description
            })
            setIsloading(false)
            window.scrollTo(0, 0);
          }
        })
        .catch((error) => {
          setIsloading(false)
          // console.log(error, "error");
        });
    } else {
      setGetRecommandedIdData(1)
    }
  }, [id]);
const togleHeart = ()=>{
  // console.log("ok");
  setistogle(!togle)
  if(togle){
    toast.success('Add To favorite successfully!')
    
  }else{
    toast.success('Add To Unfavorite successfully!')
  }
}
  useEffect(() => {
    setIsloading(loading)
  }, [loading]);

  return (
    <div className="main_Profile">
      <AppLoader Isloader={Isloading} />
      <TopHeader />
      <AppHeader />
      <div className="Background_trans">
        <div className="container">
          <div className="ProfileMainDiv ">
            <div className="row align-items-center mt-2 BuyProduct-pg">
              <div className="col-lg-6 col-md-6 col-xs-12 buyproductimg_container">
                <div className="selectedproductimg">
                  <img src={SeriveAllData && SeriveAllData?.data?.service?.media_link} alt="" style={{ objectFit: "unset" }} />
                </div>
                <div className="mt-2">
                  <h5 className="text-light mb-2">{SeriveAllData?.data?.service?.name}</h5>
                  <p className="text-light mb-2">{SeriveAllData?.data?.service?.description.slice(0, 150)}</p>
                  <h6 className="text-light">{SeriveAllData?.data?.service?.price} KWD</h6>
                </div>
              </div>

              <div className="col-lg-6  col-md-6 col-xs-12 d-flex align-items-center flex-column text-center">
              <div className="selecteproUser-img">
                  <img
                    src={SeriveAllData?.data?.service?.user?.image}
                    alt="Profile Pic"
                    className="Profile_Pic rounded-pill"
                  />
                  {/* {console.log(SeriveAllData?.data?.service?.user?.license_number)} */}
                  {SeriveAllData?.data?.service?.user?.license_number ? (
                      <div className="licensebadge">
                        <img src={badgeimg} alt="License Badge" />
                      </div>
                  ) : (
                    ""
                  )}
                </div>
                <p className="text-light username">{t(SeriveAllData?.data?.service?.user?.name)}</p>
                <h4 className="star">
                  <div className="d-flex">

                    <RatingStar Star={SeriveAllData?.data?.user_rating} />
                  </div>
                  <div
                    className="mx-5 hr"
                    style={{
                      border: "2px solid rgb(65, 142, 48)",
                      margin: "18px",
                      textAlign: "center",
                      width: 300,
                      display: "flex",
                      justifyContent: "center"

                    }}
                  />
                </h4>
                {" "}
                <div className="AllMedia">
                  <VerifyUserForShare type={"Instagram"} UserCredential={UserCredential} MerchantMedia={MerchantMedia} config={config} t={t} />
                  <VerifyUserForShare type={"Rating"}MerchantMedia={MerchantMedia} UserCredential={UserCredential && UserCredential} id={id} config={config} t={t} />
                  <VerifyUserForShare type={"Whatsapp"} UserCredential={UserCredential} MerchantMedia={MerchantMedia} config={config} t={t} />
                  <div onClick={togleHeart} className={` ${togle ? 'heart-main' : ''}`}>

                  <VerifyUserForShare className="heart-favorite" type="Addtofavourite" UserCredential={UserCredential} config={config} MerchantMedia={MerchantMedia} t={t} />
                  </div>
                  <div className="social_Media" onClick={() => setSharedropdown(!Sharedropdown)} style={{ position: "relative", cursor: "pointer" }}>
                    <img src={share} alt="" />
                    <p className="text-light  m-0">{t("Share")}</p>
                    {Sharedropdown &&
                      <div className="share-select-dropdown">
                        <div className="share-list">
                          <div>
                            <p className="m-0">
                              Share via:
                            </p>
                          </div>
                          <div>
                            <FacebookShareButton url={MediaLink?.url} quote={MediaLink?.title}>
                              <FacebookIcon size={36} round />
                            </FacebookShareButton>
                          </div>
                          <div>
                            <TwitterShareButton
                              url={MediaLink?.url}
                              title={MediaLink?.title}
                            >
                              <TwitterIcon size={36} round />
                            </TwitterShareButton>
                          </div>
                          <div>
                            <WhatsappShareButton url={MediaLink?.url} title={`${MediaLink?.title}\nCheck out this product!`} separator=" ">
                              <WhatsappIcon size={36} round />
                            </WhatsappShareButton>
                          </div>
                          <div>
                            <EmailShareButton
                              url={MediaLink?.url}
                              subject={"Check out this product!"}
                              body={`I found this amazing product:  ${MediaLink?.title}! Check it out here: ${MediaLink?.url}`}
                              separator=" - "
                            >
                              <EmailIcon size={36} round />
                            </EmailShareButton>
                          </div>
                          <div>
                            <TelegramShareButton
                              url={MediaLink?.url}
                              title={MediaLink?.title}
                            >
                              <TelegramIcon size={36} round />
                            </TelegramShareButton>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RecommendedBanner RecommendedData={SeriveAllData && SeriveAllData} />
      <Footer />
    </div >
  );
}

export default BuyProduct;