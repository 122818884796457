// import { Button, Slider } from "antd";
import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Slider } from 'antd';
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import usePost from "../../CustomHooks/UsePost";
import { useNavigate, useParams } from "react-router-dom";
import { MainUsersContext } from "../../Context/UserContext";
import { toast } from "react-hot-toast";
import config from "../../Services/Config.json"
gsap.registerPlugin(ScrollTrigger);

function RatingCard() {
  const { UserCredential } = useContext(MainUsersContext);
  const { id } = useParams()
  const { t } = useTranslation();
  const [Response, setResponse] = useState();
  const [Rating, setRating] = useState(0);
  const [res, apiMethod] = usePost();
  const navigate = useNavigate()

  const onChange = (value) => {
    setRating(value)
  };
  const marks = {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5'
  };
  const HAndleSendResponse = () => {
    const formData = new FormData();
    if (Response?.trim() && Rating > 0) {
      formData.append("review_detail", Response);
      formData.append("rating", Rating);
      formData.append("service_id", id);
      formData.append("user_id", UserCredential?.id);
      apiMethod("reviews/add", formData);
    } else if (Rating == 0) {
      toast.error(t("Rating must be greater than 0"), {
        icon: '⚠️',
        duration: 4000,
        position: "top-right",
        style: {
          background: '#FF822D',
          color: "#fff"
        },
      })
    }
  }


  useEffect(() => {
    if (res?.data?.message) {
      navigate(`/${config.ddemoss}buyproduct/${id}`)
      window.scrollTo(0, 0);
      toast.success(t("Review Add Successfully"), {
        icon: '👏',
        duration: 5000,
        style: {
          background: '#75c936 ',
          color: "#fff",
          minWidth: 200
        },
      });
      setResponse("")
      setRating(0)
    }
  }, [res.data]);

  useEffect(() => {
    gsap.set('.rating', { opacity: 0, scale: 0.5, transformOrigin: 'center center', rotationY: 180 });

    ScrollTrigger.create({
      trigger: '.rating',
      start: 'top 80%',
      onEnter: () => {
        gsap.to('.rating', {
          opacity: 1,
          scale: 1,
          rotationY: 0,
          duration: 0.9,
          ease: 'power1.out',
        });
      },
      once: true,
    });
  }, []);


  return (
    <div className="rating">
      <div className="ratCard_BackGrnd">
        <h5>{t("HowWasYourExperience")}</h5>
        <textarea value={Response} onChange={(e) => setResponse(e.target.value)} placeholder={t("Describeyourexperience")} />
        <h4 className="mt-3">{t("Star")}</h4>
        {/* <Slider defaultValue={30}  /> */}
        <div className="sldmain">
          <Slider value={Rating} marks={marks} defaultValue={0} min={0} max={5} onChange={onChange} />
        </div>
        <Button onClick={HAndleSendResponse} className="my-3 button" variant="h6" >
          {t("Submit")}
        </Button>
      </div>
    </div>
  );
}

export default RatingCard;
