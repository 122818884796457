import React from 'react';
import { FaStar } from 'react-icons/fa';

const RatingStar = ({ Star, recommended }) => {
    // Assuming userRating is a value between 1 and 5
    const starsArray = Array.from({ length: Star });
    return (
        starsArray?.map((_, index) => (
            <FaStar
                key={index}
                size={recommended ? 15 : 25}
                className="mx-1"
                // Active color if index is less than user rating, otherwise inactive color
                color={"#ffc107"}
            />
        ))
    )

};

export default RatingStar;