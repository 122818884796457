import config from "../Services/Config.json";
import { useState } from "react";
// import HttpService from "../Services/HttpService";
import { getTokenSession } from "../MainFunction";
import http from "../Services/httpService";

const usePost = () => {
    const [res, setRes] = useState({ data: null, error: null, isLoading: false });
    const authentication = getTokenSession();
    let headers = {
        "Content-Type": "multipart/form-data",
    }
    if (authentication) {
        headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authentication}`,
        };
    }

    const callAPI = async (url, payload) => {
        setRes((prevState) => ({ ...prevState, isLoading: true }));
        http.post(`${config.apiEndPoint}${url}`, payload, {
            headers,
        })
            .then((res) => {
                setRes({ data: res.data, isLoading: false, error: null });
            })
            .catch((error) => {
                // console.log(error, "Post Error");
                setRes({ data: null, isLoading: false, error });
            });
    };
    return [res, callAPI];
};
export default usePost;
