import { createContext, useEffect, useState } from "react";
export const mainContentTextProdvider = createContext();
function ContentText({ children }) {
    const [Content, setContent] = useState();


    return (
        <mainContentTextProdvider.Provider value={{ setContent, Content }}>
            {children}
        </mainContentTextProdvider.Provider>

    );
}

export default ContentText;