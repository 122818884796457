import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosSend } from 'react-icons/io'
import { Link } from 'react-router-dom';
import { mainContentTextProdvider } from '../Context/ContentText';
import usePost from '../CustomHooks/UsePost';
import { toast } from 'react-hot-toast';
import config from "../Services/Config.json"
import { languageContextProvider } from '../Context/LangaugeContext';

const Footer = ({ data }) => {
  const { t } = useTranslation();
  const { Content } = useContext(mainContentTextProdvider);
  const { langName } = useContext(languageContextProvider);
  const [Disabled, setDisabled] = useState(false);
  // console.log();

  const [res, apiMethod] = usePost();
  const [Email, setEmail] = useState();

  const HandleSubscribe = () => {
    if (Email?.trim()) {
      let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (regex.test(Email)) {
        setDisabled(true)
        const formData = new FormData();
        formData.append("email", Email);
        apiMethod("subcribe-email", formData);
      }
    }
  }

  useEffect(() => {
    if (res?.data?.message) {
      setEmail("")
      setDisabled(false)
      toast.success(t('Subsribe Successfully!'), {
        icon: '👏',
        duration: 5000,
        position: "bottom-right",
        style: {
          background: '#75c936',
          color: "#fff"
        },
      });
    }
  }, [res?.data]);


  const FooterLink = ({ item, index }) => {

    let imageUrl;
    switch (item?.name?.toLowerCase()) {
      case 'facebook':
        imageUrl = require("../Assets/WebImages/Facebook.png");
        break;
      case 'instagram':
        imageUrl = require("../Assets/WebImages/Instagram.png");
        break;
      case 'twitter':
        imageUrl = require("../Assets/WebImages/Twitter.png");
        break;
      case 'linkedin':
        imageUrl = require("../Assets/WebImages/Linkdain.png");
        break;
      case 'whatsapp':
        imageUrl = require("../Assets/WebImages/Whatsapp1.png");
        break;
      default:
        imageUrl = null;
    }

    const openPageInNewTab = (e) => {
      window.open(e, '_blank');
    };

    return imageUrl ? (
      <div style={{ width: '40px' }}>
        <Link to="#" onClick={() => openPageInNewTab(`${item?.link}`)}>
          <img style={{ width: '100%' }} src={imageUrl} alt={item.name} />
        </Link>
      </div>
    ) : null;
  }



  return (
    <>
      <div className='footer_container'>
        <div className="row m-0 p-0">
          <div className="col-md-5 col-sm-12 cl1">
            <div className='ftimg mb-4'>
              <img src={require("../Assets/WebImages/WebLogo.png")} alt="" />
            </div>
            <div className="mb-3">
              <div className="footercontentdm" dangerouslySetInnerHTML={{ __html: Content && langName == "arabic" ? Content?.arabic_footer_text : Content?.footer_text }} />
            </div>
            <div style={{ display: 'flex', paddingBottom: '15px', gap: 5 }}>
              {Array.isArray(Content?.social_links) && Content?.social_links?.length > 0 && Content?.social_links?.map((item, index) => {
                return (
                  <FooterLink key={index} item={item} index={index} />
                )
              })}
              {/* <div style={{ width: '40px' }}>
                <Link to="#" onClick={() => openPageInNewTab("https://www.facebook.com")}><img style={{ width: '100%' }} src={require("../Assets/WebImages/Facebook.png")} /></Link>
              </div>
              <div style={{ width: '40px', marginLeft: '10px' }}>
                <Link to="#" onClick={() => openPageInNewTab("https://www.twitter.com")}><img style={{ width: '100%' }} src={require("../Assets/WebImages/Twitter.png")} /></Link>
              </div>
              <div style={{ width: '40px', marginLeft: '10px' }}>
                <Link to="#" onClick={() => openPageInNewTab("https://www.instagram.com")}><img style={{ width: '100%' }} src={require("../Assets/WebImages/Instagram.png")} /></Link>
              </div>
              <div style={{ width: '40px', marginLeft: '10px' }}>
                <Link to="#" onClick={() => openPageInNewTab("https://www.linkedin.com")}><img style={{ width: '100%' }} src={require("../Assets/WebImages/Linkdain.png")} /></Link>
              </div>
              <div style={{ width: '40px', marginLeft: '10px' }}>
                <Link to="#" onClick={() => openPageInNewTab("https://www.whatsapp.com")}><img style={{ width: '100%' }} src={require("../Assets/WebImages/Whatsapp1.png")} /></Link>
              </div> */}
            </div>
          </div>
          <div className="col-md-2 col-sm-12 cl2">
            <div className="mb-4">
              <h3>{t('UsefulLink')}</h3>
            </div>
            <ul>
              <Link to={`/${config.ddemoss}aboutus`}>
                <li>
                  <span>{t('aboutUs')}</span>
                </li>
              </Link>
              <Link to={`/${config.ddemoss}contact`}>
                <li>
                  <span>{t('contactus')}</span>
                </li>
              </Link>
              <Link to={`/${config.ddemoss}login`}>
                <li>
                  <span>{t('Login')}</span>
                </li>
              </Link>
              <Link to={`/${config.ddemoss}signup`}>
                <li>
                  <span>{t('Signup')}</span>
                </li>
              </Link>
              <Link to={`/${config.ddemoss}privacypolicy`}>
                <li>
                  <span>{t('Privacy Policy')}</span>
                </li>
              </Link>
            </ul>
          </div>
          <div className="col-md-2 col-sm-12 cl2">
            <div className="mb-4">
              <h3>{t('OurServices')}</h3>
            </div>
            <ul>
              {Content && Content?.categories?.length > 0 && Content?.categories.slice(0, 5)?.map((item, index) => {
                return (
                  <Link to={`/${config.ddemoss}category/${item?.name}/${item?.id}`} key={index}>
                    <li>
                      <span>{langName == "arabic" ? item?.arabic_name : item?.name}</span>
                    </li>
                  </Link>
                )
              })}
            </ul>
          </div>

          <div className="col-md-3 col-sm-12 cl2">
            <div className="mb-4">
              <h3>{t('Newsletter')}</h3>
            </div>
            <ul>
              <li>
                <span>{t('Subscribe us for latest update.')}</span>
              </li>
              <li>
                <div className='ft-inp'>
                  <input type="text" value={Email} placeholder={t('Enter your email')} onChange={(e) => setEmail(e.target.value)} /><span onClick={() => {
                    if (!Disabled) {
                      HandleSubscribe();
                    }
                  }}
                    style={{ cursor: Disabled ? "not-allowed" : "pointer", color: Disabled ? "gray" : "inherit" }} ><IoIosSend style={{ cursor: "pointer" }} /></span>
                </div>
                <div className='d-flex sm-d-flex footerappimage gap-2 mt-4 '>
                  <Link to={Content?.play_store_link ? Content?.play_store_link : ""} target="_blank">
                    <div style={{ width: 120 }}>
                      <img src={require("../Assets/WebImages/googleplay.png")} alt="" style={{ width: "100%", height: "100%" }} />
                    </div>
                  </Link>
                  <Link to={Content?.app_store_link ? Content?.app_store_link : ""} target="_blank">
                    <div style={{ width: 120 }}>
                      <img src={require("../Assets/WebImages/appstore.png")} alt="" style={{ width: "100%", height: "100%" }} />
                    </div>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='footer_container_Copyrighted'>
        <h3>
          {t('copyright')}
        </h3>
      </div>
    </>
  )
}

export default Footer