import { Button, Checkbox } from '@mui/material'
import { useEffect, useState, useContext } from 'react'
import { MdEmail } from "react-icons/md";
import { FaLock, FaUser } from "react-icons/fa";
import IEmail from "../Assets/WebImages/mail.png"
import Lock from "../Assets/WebImages/lock.png"
import Profile from "../Assets/WebImages/profile1.png"
import idcard from "../Assets/WebImages/idcard1.png"
import Otp from "../Assets/WebImages/otp.png"
import { CiMobile2 } from "react-icons/ci";
import { TbDeviceMobileMessage } from "react-icons/tb";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import config from "../Services/Config.json"
import FlagsSelect from 'react-flags-select';
import { countries } from 'countries-list';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as Yup from 'yup';
import { FaIdCard } from "react-icons/fa";
import { toast } from 'react-hot-toast';
import "aos/dist/aos.css"
import { setEmailSession, setIdSession, setTokenSession } from '../MainFunction';
import { MainUsersContext } from '../Context/UserContext'


gsap.registerPlugin(ScrollTrigger);

const Allcards = ({ forpage }) => {
    const navigate = useNavigate();
    const [country, setCountry] = useState('');
    const [UserType, setUserType] = useState("merchant");
    const [selectedCode, setSelectedCode] = useState('');
    const [IsSubmitting, setIsSubmitting] = useState(false);
    const [Seconds, setSeconds] = useState(60);
    const Email = localStorage.getItem("email")
    const { t, i18n } = useTranslation("translation");
    const [isChecked, setIsChecked] = useState(true);

    const { HandleUserCredential } = useContext(MainUsersContext);

    const validationSchemaResgister = Yup.object().shape({
        name: Yup.string()
            .transform((value) => value.trim()) // Trim whitespace
            .min(3, t("Too Short"))
            .required(t("required")),
        email: Yup.string().email(t('Invalid email')).required(t(`Email is required`)),
        password: Yup.string()
            .matches(
                /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=!]).*$/,
                t('Password must contain at least')
            )
            .min(8, t('Password at least 8 characters'))
            .required(t('Password is required')),
        // country: Yup.string().required('Country is required'),
    });

    const validationSchemaLogin = Yup.object().shape({
        email: Yup.string().email(t('Invalid email')).required(t(`Email is required`)),
        password: Yup.string()
            .matches(
                /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=!]).*$/,
                t('Password must contain at least')
            )
            .min(8, t('Password at least 8 characters'))
            .required(t('Password is required')),
    });

    const validationSchemaForgeotPass = Yup.object().shape({
        email: Yup.string().email(t('Invalid email')).required(t(`Email is required`)),
    });

    const validationSchemaForgeotVerifi = Yup.object().shape({
        otp: Yup.string()
            .matches(/^[0-9]*$/, t('OTP must be a 4-digit number'))
            .min(3, t('OTP is incorrect'))
            .max(4, t('OTP is incorrect')),
        password: Yup.string()
            .matches(
                /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=!]).*$/,
                t('Password must contain at least')
            )
            .min(8, t('Password at least 8 characters'))
            .required(t('Password is required')),
        confirmpassword: Yup.string()
            .matches(
                /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=!]).*$/,
                t('Password must contain at least')
            )
            .min(8, t('Password at least 8 characters'))
            .required(t('Confirm Password is required')),

    });

    const initialValues = {
        name: '',
        email: '',
        phonenumber: '',
        password: '',
        country: country,
        license_number:'',
        last_name:''
    }
    const initialValuesForLogin = {
        email: "",
        password: ""
    }

    const initialValuesForForg = {
        email: "",
    }

    const initialValuesForForgVerify = {
        otp: "",
        password: "",
        confirmpassword: ""
    }

    useEffect(() => {
        if (Seconds === 0) return;
        const timerInterval = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);

        return () => clearInterval(timerInterval);
    }, [Seconds]);

    const datalogin = [
        {
            id: 1,
            placeholder: t("Enteryouremail"),
            icon: <MdEmail size={18} color='#2B9229' />,
            icon1: <img src={IEmail} width={18} height={18} color='#2B9229' />,
            type: "email",
            name: "email",

        },
        {
            id: 2,
            placeholder: t("Password"),
            icon: <FaLock size={18} color='#2B9229' />,
            icon1: <img src={Lock} width={18} height={18} color='#2B9229' />,
            type: "Password",
            name: "password"
        },
    ];

    const dataregister = [
        {
        id: 1,
        placeholder: t("First name"),
        icon: <FaUser size={18} color='#2B9229' />,
        icon1: <img src={Profile} width={18} height={18} color='#2B9229' />,
        type: "text",
        name: "name",
        // mywidth:"50%"
    },
        {
        id: 2,
        placeholder: t("Last name"),
        icon: <FaUser size={18} color='#2B9229' />,
        icon1: <img src={Profile} width={18} height={18} color='#2B9229' />,
        type: "text",
        name: "last_name"
    },
    {
        id: 3,
        placeholder: t("Valid email"),
        icon: <MdEmail size={18} color='#2B9229' />,
        icon1: <img src={IEmail} width={18} height={18} color='#2B9229' />,
        type: "email",
        name: "email"
    },
    {
        id: 4,
        placeholder: t("Phone number"),
        icon1: <CiMobile2 size={18} color='#2B9229' />,
        type: "number",
        name: "phonenumber"
    },
    {
        id: 5,
        placeholder: t("Strong Password"),
        icon: <FaLock size={18} color='#2B9229' />,
        icon1: <img src={Lock} width={18} height={18} color='#2B9229' />,
        type: "Password",
        name: "password"
    },
    {
        id: 6,
        placeholder: t("License number"),
        icon: <FaIdCard  size={18} color='#2B9229' />,
        icon1: <img src={idcard} width={18} height={18} color='#2B9229' />,
        type: "text",
        name: "license_number",
        maxlength:20
    },
    ];

    const datalForgotpas = [
        {
            id: 1,
            placeholder: t("Enteryouremail"),
            icon: <MdEmail size={18} color='#2B9229' />,
            icon1: <img src={IEmail} width={18} height={18} color='#2B9229' />,
            type: "email",
            name: "email"
        },
    ];

    const datalForgotpasVerify = [
        {
            id: 2,
            placeholder: t("OTP"),
            icon: <TbDeviceMobileMessage size={18} color='#2B9229' />,
            icon1: <img src={Otp} width={20} height={20} color='#2B9229' />,
            type: "number",
            name: "otp"
        },
        {
            id: 3,
            placeholder: t("New Password"),
            icon: <FaLock size={18} color='#2B9229' />,
            icon1: <img src={Lock} width={18} height={18} color='#2B9229' />,
            type: "Password",
            name: "password"
        },
        {
            id: 3,
            placeholder: t("Confirm Password"),
            icon: <FaLock size={18} color='#2B9229' />,
            icon1: <img src={Lock} width={18} height={18} color='#2B9229' />,
            type: "Password",
            name: "confirmpassword"
        },
    ];


    const handleSelectCountrt = (code) => {
        setSelectedCode(code);
        const countryName = countries[code]?.name;
        setCountry(countryName)
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleSubmit = (values) => {
        if (isChecked) {
            setIsSubmitting(true)
            let formdata = new FormData();
            for (const key in values) {
                if (key != "country" && key != "phonenumber") {
                    formdata.append(key, values[key]);
                }
                if (key == "phonenumber") {
                    formdata.append("phone_number", values[key]);
                }
            }

            UserType && formdata.append("type", UserType);
            UserType && formdata.append("country", initialValues.country);
            axios.defaults.headers = {
                "Content-Type": "multipart/form-data",
            };
            axios.post(`${config.apiEndPoint}signup`, formdata)
                .then((response) => {
                    if (response) {
                        setIsSubmitting(false)
                        if ((response?.data?.status && response?.data?.message == "Register Successfully,Please Check your email" && response?.data?.status_code == 200)) {
                            setEmailSession(values?.email);
                            
                            toast.success(t(response?.data?.message), {
                                icon: '👏',
                                duration: 5000,
                            });
                            setTimeout(() => {
                                // navigate(`/verification`);
                                navigate(`/${config.ddemoss}verification`);
                            }, 1000);
                        } else if (!response?.data?.success || response?.data?.message == "Validation errors") {
                            const errorMessage = Object.values(response?.data?.errors)
                                .reduce((acc, messages) => acc?.concat(messages), [])
                                .join('\n');
                            toast.error(t(errorMessage), {
                                icon: '⚠️',
                                duration: 4000,
                                position: "top-right",
                                style: {
                                    background: '#FF822D',
                                    color: "#fff"
                                },
                            });
                        }
                    }
                })
                .catch((error) => {
                    setIsSubmitting(false)
                    toast.error(t('Something went wrong'), {
                        icon: '⚠️',
                        duration: 4000,
                        position: "top-right",
                        style: {
                            background: '#FF822D',
                            color: "#fff"
                        },
                    });
                });
        }
    }
    const HandleSubmitLogin = (values) => {
        setIsSubmitting(true)
        let formdata = new FormData();

        for (const key in values) {
            formdata.append(key, values[key]);
        }

        formdata.append("type", UserType);
        axios.defaults.headers = {
            "Content-Type": "multipart/form-data",
        };
        axios.post(`${config.apiEndPoint}login`, formdata)
            .then((response) => {
                if (response) {
                    setIsSubmitting(false)
                    if ((response?.data?.status && response?.data?.message == "Login Done" && response?.data?.status_code == 200)) {
                        setIdSession(response?.data?.data?.user?.id)
                        setTokenSession(response?.data?.data?.token)
                        toast.success(t('Login successfully!'), {
                            icon: '👏',
                            duration: 5000,
                            style: {
                                background: '#75c936',
                                color: "#fff"
                            },
                        });

                        HandleUserCredential(response?.data?.data?.user, response?.data?.data?.token);
                        setTimeout(() => {
                            navigate(`/${config.ddemoss}`)
                        }, 1000);
                    } else if (response?.data.error_code == "unauthorized" && response?.data.message == "Please verify your email before logging in.") {
                        setEmailSession(values?.email);
                        toast.error(t('Verify email address'), {
                            icon: '⚠️',
                            duration: 4000,
                            position: "top-right",
                            style: {
                                background: '#FF822D',
                                color: "#fff"
                            },
                        });
                        // navigate("/verification")
                        navigate(`/${config.ddemoss}verification`);
                    } else if (response?.data.error_code == "unauthorized" || response?.data.message == "You Must Login") {
                        toast.error(t(response?.data.message), {
                            icon: '❌',
                            duration: 4000,
                            position: "top-right",
                            style: {
                                background: '#F9C23C',
                                color: "#fff",
                                minWidth: 200
                            },
                        });
                    }
                }
            })
            .catch((error) => {
                setIsSubmitting(false)
                toast.error(t('Something wrong'), {
                    icon: '❌',
                    duration: 4000,
                    position: "top-right",
                    style: {
                        background: '#F9C23C',
                        color: "#fff",
                        minWidth: 200
                    },
                });
            });
    }

    const HandleForgotpas = (values) => {
        setIsSubmitting(true)
        let formdata = new FormData();
        formdata.append("email", values.email);
        axios.defaults.headers = {
            "Content-Type": "multipart/form-data",
        };
        axios.post(`${config.apiEndPoint}forgot-password`, formdata)
            .then((response) => {
                if (response.data) {
                    setIsSubmitting(false)
                    setEmailSession(values.email)
                    toast.success(t(response?.data?.message), {
                        icon: '📬',
                        duration: 5000,
                        style: {
                            background: '#75c936',
                            color: "#fff",
                            minWidth: 200
                        },
                    });
                    navigate(`/${config.ddemoss}identify`)
                }
            })
            .catch((error) => {
                setIsSubmitting(false)
                toast.error(t(error?.response?.data?.message), {
                    icon: '⚠️',
                    duration: 4000,
                    position: "top-right",
                    style: {
                        background: '#FF822D',
                        color: "#fff",
                        minWidth: 200
                    },

                });
            });
    }

    const HandleForgortVerify = (values) => {
        if (values.password === values.confirmpassword) {
            setIsSubmitting(true)
            let formdata = new FormData();
            if (Email != "") {
                formdata.append("email", Email);
                for (const key in values) {
                    formdata.append(key, values[key]);
                }
                axios.defaults.headers = {
                    "Content-Type": "multipart/form-data",
                };
                axios.post(`${config.apiEndPoint}reset-password`, formdata)
                    .then((response) => {
                        if (response.data) {
                            setIsSubmitting(false)
                            toast.success(t(response?.data?.message), {
                                icon: '👏',
                                duration: 5000,
                                style: {
                                    background: '#75c936 ',
                                    color: "#fff",
                                    minWidth: 200
                                },

                            });
                            navigate(`/${config.ddemoss}login`)
                        }
                    })
                    .catch((error) => {
                        setIsSubmitting(false)
                        toast.error(t(error?.response?.data?.message), {
                            icon: '⚠️',
                            duration: 4000,
                            position: "top-right",
                            style: {
                                background: '#FF822D',
                                color: "#fff",
                                minWidth: 200
                            },

                        });
                    });
            }
        } else {
            toast.error(t('Password does not match'), {
                icon: '⚠️',
                duration: 4000,
                position: "top-right",
                style: {
                    background: '#FF822D',
                    color: "#fff",
                    minWidth: 200
                },
            });
        }
    }

    useEffect(() => {
        gsap.set(".CardMain", { opacity: 0, rotationY: -180, transformOrigin: "center" });
        ScrollTrigger.create({
            trigger: ".CardMain",
            start: "top 80%",
            onEnter: () => {
                gsap.to(".CardMain", {
                    opacity: 1,
                    rotationY: 0,
                    duration: 0.9,
                    ease: "power1.out",
                });
            },
            once: true,
        });
    }, []);

    return (
        <div className='CardMain my-5'>

            {/* this code is comment for client requirments -------> */}
            {/* <div className='card_header'>
                <div>
                    <Button style={{ background: UserType === "customer" ? "#2B9229" : '#F3F3F3', color: UserType === "customer" ? "#fff" : "#4F4F4F", boxShadow: 'none', fontWeight: "bold", borderRadius: "10px" }} size="large" variant="contained" onClick={() => setUserType("customer")}>
                        {t("Customer")}
                    </Button>
                </div>
                <div>
                    <Button style={{ background: UserType === "merchant" ? "#2B9229" : '#F3F3F3', color: UserType === "merchant" ? "#fff" : "#4F4F4F", boxShadow: 'none', fontWeight: "bold", borderRadius: "10px" }} size="large" variant="contained" onClick={() => setUserType("merchant")}>
                        {t("Merchant")}
                    </Button>
                </div>
            </div> */}
            <Formik
                initialValues={forpage === "signup" ? initialValues : forpage === "login" ? initialValuesForLogin :
                    forpage === "Forgot Password" ? initialValuesForForg : initialValuesForForgVerify
                }
                validationSchema={forpage === "signup" ? validationSchemaResgister : forpage === "login" ? validationSchemaLogin :
                    forpage === "Forgot Password" ? validationSchemaForgeotPass : validationSchemaForgeotVerifi
                }
                onSubmit={forpage === "signup" ? handleSubmit : forpage === "login" ? HandleSubmitLogin : forpage === "Forgot Password" ? HandleForgotpas : HandleForgortVerify}
                validateOnChange
            >
                {formik => (
                    <Form>
                        <div className="cartbody">
                            {forpage === "login" && datalogin?.map((item, index) => {
                                return (
                                    // <div key={index} className='eachitem'>
                                    //     <input type={item.type} placeholder={item.placeholder} name={item?.name} />
                                    //     {item.icon}
                                    // </div>
                                    <>
                                        <div key={index} className='eachitem'>
                                            <Field
                                                type={item.type}
                                                id={item.name}
                                                name={item.name}
                                                placeholder={item.placeholder}
                                            />
                                            {item.icon1}
                                        </div>
                                        <ErrorMessage name={item.name} component='div' className='error-message' />
                                    </>
                                )
                            })}
                            {forpage === "signup" &&
                                <div>
                                    {
                                        dataregister?.map((item, index) => (
                                            <>
                                                <div key={index} className={item.id === 1 || item.id === 2 ? 'custom-class' : 'eachitem'}>
                                                    <Field
                                                        type={item.type}
                                                        id={item.name}
                                                        name={item.name}
                                                        placeholder={item.placeholder}
                                                        maxlength={item.maxlength?item.maxlength:''}
                                                       
                                                    />
                                                    {item.icon1}
                                                </div>
                                                <ErrorMessage name={item.name} component='div' className='error-message' />
                                            </>
                                        ))
                                    }
                                    <div className="eachitem">
                                        <FlagsSelect
                                            searchable
                                            selected={selectedCode}
                                            onSelect={handleSelectCountrt}
                                            fullWidth={true}
                                            className="menu-flags-custom"
                                            placeholder={t('Select a country')}
                                        />
                                        {/* <IoLocationSharp size={18} color='#2B9229' /> */}
                                        {/* <img src={Location} size={10} color='#2B9229' /> */}
                                    </div>
                                </div>
                            }
                            {forpage === "Forgot Password" && datalForgotpas?.map((item, index) => {
                                return (
                                    <>
                                        <div key={index} className='eachitem'>
                                            <Field
                                                type={item?.type}
                                                id={item?.name}
                                                name={item?.name}
                                                placeholder={item?.placeholder}
                                            />
                                            {item?.icon1}
                                        </div>
                                        <ErrorMessage name={item.name} component='div' className='error-message' />
                                    </>
                                )
                            })}
                            {forpage === "VerifyOTP" &&
                                <>
                                    {datalForgotpasVerify?.map((item, index) => {
                                        return (
                                            <>
                                                <div key={index} className='eachitem'>
                                                    <Field
                                                        type={item?.type}
                                                        id={item?.name}
                                                        name={item?.name}
                                                        placeholder={item?.placeholder}
                                                    />
                                                    {item?.icon1}
                                                </div>
                                                <ErrorMessage name={item.name} component='div' className='error-message' />
                                            </>
                                        )
                                    })}
                                    <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: 10, fontSize: 15, color: "#f71b13" }}>
                                        00 : {Seconds > 9 ? Seconds : `0${Seconds}`} Sec
                                    </div>
                                </>
                            }
                        </div>
                        {
                            forpage === "login" &&
                            <>
                                <div className='remember'>
                                    <div>
                                        <Checkbox defaultChecked color="success" /> <label>{t("Remember")}</label>
                                    </div>
                                    <div>
                                        <span><Link to={"/forgotpassword"}> {t("Forgetpassword")}</Link></span>
                                    </div>
                                </div>
                                <div>
                                    <div className='logind'>
                                        <Button disabled={IsSubmitting} className='loginbtn' size="large" variant="contained" type='submit' color="success" >
                                            {t("login")}
                                        </Button>
                                    </div>
                                    {/* <div className='logind'>
                                        <Button className='loginbtnout' variant="contained" color="success" >
                                            {t("LOGINASGUEST")}
                                        </Button>
                                    </div> */}
                                </div>
                                {/* <div className='loginwith mt-3'>
                                    <h6 className='text-center'>
                                        {t("Loginwith")}
                                    </h6>
                                    <div className='d-flex justify-content-center gap-3'>
                                        <div className='lgwiic' style={{ cursor: "pointer" }}>
                                            <svg width="46" height="36" viewBox="0 0 46 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M35.4287 17.6836C35.4287 11.0562 30.0561 5.68359 23.4287 5.68359C16.8013 5.68359 11.4287 11.0562 11.4287 17.6836C11.4287 23.6731 15.8169 28.6376 21.5537 29.5378V21.1523H18.5068V17.6836H21.5537V15.0399C21.5537 12.0323 23.3452 10.3711 26.0863 10.3711C27.3992 10.3711 28.7725 10.6055 28.7725 10.6055V13.5586H27.2593C25.7686 13.5586 25.3037 14.4836 25.3037 15.4326V17.6836H28.6318L28.0998 21.1523H25.3037V29.5378C31.0405 28.6376 35.4287 23.6731 35.4287 17.6836Z" fill="#1877F2" />
                                                <path d="M28.0998 21.1523L28.6318 17.6836H25.3037V15.4326C25.3037 14.4836 25.7686 13.5586 27.2593 13.5586H28.7725V10.6055C28.7725 10.6055 27.3992 10.3711 26.0863 10.3711C23.3452 10.3711 21.5537 12.0323 21.5537 15.0398V17.6836H18.5068V21.1523H21.5537V29.5378C22.174 29.635 22.8009 29.6838 23.4287 29.6836C24.0666 29.6836 24.6928 29.6337 25.3037 29.5378V21.1523H28.0998Z" fill="white" />
                                            </svg>
                                        </div>

                                        <div className='lgwiic' style={{ cursor: "pointer" }} >
                                            <svg width="46" height="36" viewBox="0 0 46 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M34.5649 18.4852C34.5659 17.6961 34.4992 16.9085 34.3655 16.1309H23.4463V20.5902H29.7003C29.5724 21.3024 29.3015 21.9814 28.904 22.5861C28.5066 23.1908 27.9908 23.7088 27.3878 24.1088V27.0035H31.1203C33.3058 24.9885 34.5649 22.0086 34.5649 18.4852Z" fill="#4285F4" />
                                                <path d="M23.4462 29.8001C26.5708 29.8001 29.2017 28.7741 31.1202 27.0051L27.3878 24.1105C26.3489 24.8148 25.0109 25.2169 23.4462 25.2169C20.4261 25.2169 17.8628 23.181 16.9461 20.4375H13.1011V23.4206C14.0648 25.3383 15.5425 26.9503 17.3693 28.0768C19.1961 29.2034 21.3 29.8 23.4462 29.8001Z" fill="#34A853" />
                                                <path d="M16.9461 20.4386C16.4615 19.0009 16.4615 17.4441 16.9461 16.0065V13.0234H13.1011C12.2904 14.6366 11.8682 16.4171 11.8682 18.2225C11.8682 20.028 12.2904 21.8084 13.1011 23.4216L16.9461 20.4386Z" fill="#FBBC04" />
                                                <path d="M23.4465 11.2265C25.0977 11.1995 26.6932 11.8234 27.8881 12.9633L31.1928 9.65859C29.0973 7.69036 26.3212 6.60976 23.4465 6.64334C21.3003 6.64344 19.1963 7.24004 17.3695 8.36656C15.5428 9.49307 14.065 11.1051 13.1013 13.0228L16.9464 16.0059C17.863 13.2624 20.4264 11.2265 23.4465 11.2265Z" fill="#EA4335" />
                                            </svg>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='d-flex justify-content-center mt-3'>
                                    <p style={{ fontSize: "13px" }}>
                                        {t("NewMember")} <span style={{ color: "#2B9229" }}> <Link to={"/signup"}> {t("Signupnow")}</Link></span>
                                    </p>
                                </div>
                            </>
                        }
                        {
                            forpage === "signup" &&
                            <>
                                < div className='remember' >
                                    <div>
                                        <Checkbox
                                            checked={isChecked}
                                            onChange={handleCheckboxChange} color="success" />
                                        <label style={{ color: "#000", fontSize: "12px" }}>{t("Bycheckingtheboxyouagreetoour")}<span>{t("Terms")}</span> {t("and")} <span>{t("Conditions")}</span>.</label>
                                    </div>
                                </div>
                                <div>
                                    <div className='logind'>
                                        <Button disabled={IsSubmitting} type="submit" className='loginbtn' size="large" variant="contained" color="success"   >
                                            {t("signup")}
                                        </Button>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center mt-3'>
                                    <p style={{ fontSize: "13px" }}>
                                        {t("AlreadyaMember")} <span style={{ color: "#2B9229" }}><Link to={"/login"}>{t("login")}</Link></span>
                                    </p>
                                </div>
                            </>
                        }
                        {
                            forpage === "Forgot Password" && <div>
                                <div className='logind'>
                                    <Button className='loginbtn' disabled={IsSubmitting} type='submit' size="large" variant="contained" color="success"  >
                                        {t("Submit")}
                                    </Button>
                                </div>
                            </div>
                        }
                        {
                            forpage === "VerifyOTP" && <div>
                                <div className='logind'>
                                    <Button className='loginbtn' disabled={IsSubmitting} type='submit' size="large" variant="contained" color="success" onClick={() => Seconds <= 0 && navigate(`/${config.ddemoss}forgotpassword`)} >
                                        {t("Submit")}
                                    </Button>
                                </div>
                            </div>
                        }
                    </Form>
                )}
            </Formik>
        </div >
    )
}

export default Allcards
