import { useEffect, useRef } from "react";

function AppLoader({ Isloader }) {
    const loaderRef = useRef(null);

    useEffect(() => {
        const loaderElement = loaderRef.current;
        if (Isloader) {
            // If loading is true, show the loader
            loaderElement.style.height = "100%";
            document.body.style.height = "100vh";
            document.body.style.overflow = "hidden";
        } else {
            loaderElement.style.height = "0";
            document.body.style.height = "unset";
            document.body.style.overflow = "unset";
        }
    }, [Isloader]);
    return (
        <div ref={loaderRef} className="loaderContainer">
            <div className="loader">
                <div className="loader_bar"></div>
                <div className="loader_bar"></div>
                <div className="loader_bar"></div>
                <div className="loader_bar"></div>
                <div className="loader_bar"></div>
                <div className="loader_ball"></div>
            </div>
        </div>
    );
}

export default AppLoader;