import { useContext, useEffect, useRef, useState } from "react"
import { Button } from '@mui/material'
import CategoryOneImage from "../../Assets/WebImages/Categoryone.png";
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import axios from 'axios';
import { useNavigate } from "react-router";
import { toast } from 'react-hot-toast';
import config from "../../Services/Config.json";
import "aos/dist/aos.css"
import useFetch from "../../CustomHooks/UseFetch";
import * as Yup from 'yup';
import { getTokenSession } from "../../MainFunction";
import { MainUsersContext } from "../../Context/UserContext";
import AppLoader from "../AppLoader";
import { TiCameraOutline } from "react-icons/ti";
import LeftSideBar from "../Profile/LeftSideBar";

gsap.registerPlugin(ScrollTrigger);

function ProfileCard({ title }) {
    const { t } = useTranslation();
    const { data: editData, loading, error } = useFetch("edit-profile");
    const [selectedImage, setSelectedImage] = useState(null);
    const [fileInputVisible, setFileInputVisible] = useState(false);
    const { HandleUserCredential } = useContext(MainUsersContext);
    const [ImgShareTo, setImgShareTo] = useState();
    const [Isloading, setIsloading] = useState(true);
    const [BtnDisabled, setBtnDisabled] = useState(false);

    useEffect(() => {
        setIsloading(loading)
    }, [loading]);



    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const authentication = getTokenSession();
    const [InitialValues, setInitialValues] = useState({
        name: '',
        last_name: '',
        email: '',
        about_us: '',
        phone_number: '',
        whatsapp: '',
        instagram: '',
        country: '',
        license_number: '',
    });
    // console.log(InitialValues);
    const handleImageClick = () => {
        setFileInputVisible(true);
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e, formik) => {
        const selectedFile = e?.target?.files[0];
        // console.log(selectedFile);
        setImgShareTo(selectedFile);
        const data = URL.createObjectURL(selectedFile)
        // console.log(data);
        setSelectedImage(data);
        setFileInputVisible(false);
    };


    const validationSchemaProfile = Yup.object().shape({
        name: Yup.string()
            .transform((value) => value.trim())
            .min(3, t("Too Short"))
            .required(t("required")),
        email: Yup.string().email(t('Invalid email')).required(t(`Email is required`)),
    })

    useEffect(() => {
        // console.log(editData);
        if (editData) {
            setInitialValues({
                name: editData?.name || '',
                last_name: editData?.last_name || '',
                email: editData?.email || '',
                about_us: editData?.about_us || '',
                phone_number: editData?.phone_number || '',
                whatsapp: editData?.whatsapp || '',
                instagram: editData?.instagram || '',
                country: editData?.country || '',
                license_number: editData?.license_number || '',
               
            })
            setSelectedImage(editData?.image);
        }
    }, [editData]);




    useEffect(() => {
        gsap.set(".productcardmain", { opacity: 0, scale: 0.5, transformOrigin: "center center" });
        ScrollTrigger.create({
            trigger: ".productcardmain",
            start: "top 80%",
            onEnter: () => {
                gsap.to(".productcardmain", {
                    opacity: 1,
                    scale: 1,
                    duration: 0.9,
                    ease: "power1.out",
                });
            },
            once: true,
        });
    }, []);

    useEffect(() => {
        gsap.set(".productcardtwo", { opacity: 0, x: 100 });

        ScrollTrigger.create({
            trigger: ".productcardtwo",
            start: "top 80%",
            onEnter: () => {
                gsap.to(".productcardtwo", {
                    opacity: 1,
                    x: 0,
                    duration: 0.9,
                    ease: "power1.out",
                });
            },
            once: true,
        });
    }, []);
   

    const HandleSubmit = (values) => {
        // console.log(selectedImage);
        setBtnDisabled(true)
        let imagePath;
        if (editData?.image) {
            const urlPrefix = "https://admin.q8hafeez.com/admin/images/";
             imagePath = editData.image.replace(urlPrefix, "");
            // console.log(imagePath, "akakakak");
        } 
        const formData = new FormData();
        formData.append("name", values.name)
        formData.append("last_name", values.last_name)
        formData.append("email", values.email)
        formData.append("about_us", values.about_us)
        formData.append("phone_number", values.phone_number)
        formData.append("whatsapp", values.whatsapp)
        formData.append("instagram", values.instagram)
        formData.append("country", values.country)
        formData.append("type", editData && editData?.type)
        formData.append("license_number", values?.license_number)
        formData.append("image",ImgShareTo?ImgShareTo:imagePath)
        // console.log(editData?.image,"akakakak")
        // console.log(formData);
        axios.post(`${config.apiEndPoint}update-profile/${editData?.id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${authentication}`,
            },
        })
            .then((response) => {
                if (response) {
                    const token = getTokenSession()
                    setBtnDisabled(false)
                    HandleUserCredential(response?.data, token)
                    toast.success(t('Edit successfully!'), {
                        icon: '👏',
                        duration: 5000,
                        style: {
                            background: '#75c936',
                            color: "#fff"
                        },
                    });
                    navigate(`/${config.ddemoss}profile`);
                }
            })
            .catch((error) => {
                setBtnDisabled(false)
                toast.error(t('Something wrong'), {
                    icon: '❌',
                    duration: 4000,
                    position: "top-right",
                    style: {
                        background: '#F9C23C',
                        color: "#fff",
                        minWidth: 200
                    },
                });
            });

    };


    return (
        <>
            <AppLoader Isloader={Isloading} />
            <div className="productmain ">
                <LeftSideBar/>
                <div className='productcardmain'>
                    <div>
                        <div className="lftheader d-flex align-items-center  gap-4">
                            <div className="lftheader d-flex  gap-4">
                                <div className="profile-upload-container" onClick={handleImageClick} style={{ cursor: "pointer" }}>
                                    {selectedImage ? (
                                        <img src={selectedImage} alt="" />
                                    ) : (
                                        <img src={CategoryOneImage} alt="Default" />
                                    )}

                                    {fileInputVisible && title !== "userprofile" && (
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleFileChange(e, Formik)}
                                            ref={fileInputRef}
                                            disabled={title === "userprofile"}
                                        />
                                    )}
                                    {title !== "userprofile" && <div className="up-insert">
                                        <TiCameraOutline color="#fff" size={20} onClick={handleImageClick} style={{ cursor: "pointer" }} />
                                    </div>}
                                </div>
                            </div>
                            {title === "userprofile" && <Button className='userprofilebtnal' variant="contained"
                                onClick={() => navigate(`/${config.ddemoss}editprofile`)} size="medium"
                            >
                                {t("EditProfile")}
                            </Button>}
                        </div>
                        <div>
                            <Formik
                                initialValues={InitialValues}
                                enableReinitialize
                                validationSchema={validationSchemaProfile}
                                onSubmit={HandleSubmit}
                                validateOnChange
                            >
                                {(formik) => (
                                    <Form>
                                        <div className="d-flex res-productcardmain  my-3 gap-3">
                                            <div className="each-input">
                                                <Field
                                                    type="text"
                                                    name="name"
                                                    placeholder={t("YourName")}
                                                    disabled={title === "userprofile" && true}
                                                />
                                                <ErrorMessage name="name" component='div' className='error-message mt-1 ' />
                                            </div>
                                            <div className="each-input">
                                                <Field
                                                    type="text"
                                                    name="last_name"
                                                    placeholder={t("Last name")}
                                                    disabled={title === "userprofile" && true}
                                                />
                                                <ErrorMessage name="name" component='div' className='error-message mt-1 ' />
                                            </div>
                                            {/* <div className='each-input'>
                                                <input type="text" placeholder={t("Email")} name="email" />
                                                <Field
                                                    type="text"
                                                    name="email"
                                                    placeholder={t("Email")}
                                                    disabled={title === "userprofile" && true}
                                                />
                                                <ErrorMessage name="email" component='div' className='error-message mt-1 ' />

                                            </div> */}
                                        </div>
                                        <div className='my-3 w-100'>
                                            {/* <textarea placeholder={t("About Us")} name="about_us" /> */}
                                            <Field
                                                type="textarea"
                                                as="textarea"
                                                name="about_us"
                                                placeholder={t("About Us")}
                                                disabled={title === "userprofile" && true}
                                            />
                                            {/* <ErrorMessage name="email" component='div' className='error-message mt-1 ' /> */}

                                        </div>
                                        <div className="my-3 w-100 ">
                                                <div className="w-100 each-input2">
                                                <Field
                                                    type="text"
                                                    name="license_number"
                                                    placeholder={t("Licence Number")}
                                                    disabled={title === "userprofile" && true}
                                                />
                                                </div>
                                                {/* <ErrorMessage name="name" component='div' className='error-message mt-1 ' /> */}
                                            </div>
                                        <div className="d-flex res-productcardmain  my-3 gap-3">
                                            <div className="each-input">
                                                {/* <input type="text" placeholder={t("Phone")} name="phone_number" /> */}
                                                <Field
                                                    type="text"
                                                    name="phone_number"
                                                    placeholder={t("Phone")}
                                                    disabled={title === "userprofile" && true}
                                                />
                                            </div>
                                            <div className='each-input'>
                                                {/* <input type="text" placeholder={t("Whatsapp")} name="whatsapp" /> */}
                                                <Field
                                                    type="text"
                                                    name="whatsapp"
                                                    placeholder={t("Whatsappnumber")}
                                                    disabled={title === "userprofile" && true}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex res-productcardmain  my-3 gap-3">
                                            <div className="each-input">
                                                {/* <input type="text" placeholder={t("Instagram")} name="instagram" /> */}
                                                <Field
                                                    type="text"
                                                    name="instagram"
                                                    placeholder={t("Instagramprofilelink")}
                                                    disabled={title === "userprofile" && true}
                                                />
                                            </div>
                                            <div className='each-input'>
                                                {/* <input type="text" placeholder={t("Country")} name="country" /> */}
                                                <Field
                                                    type="text"
                                                    name="country"
                                                    placeholder={t("Country")}
                                                    disabled={title === "userprofile" && true}
                                                />
                                            </div>
                                        </div>
                                        {title === "editprofile" &&
                                            <div className='w-50 mt-4'>
                                                <Button className='productbtn' variant="contained" size="medium" type="submit" disabled={BtnDisabled}
                                                // onClick={() => { UpdateUser(formik); }} 
                                                >
                                                    {t("Save")}
                                                </Button>
                                            </div>
                                        }
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
                {/* <div className='productcardtwo'>
                    <ProductCom2 />
                </div> */}
            </div >
        </>
    );
}

export default ProfileCard;