import { useEffect, useState } from "react";
import AllBanners from "../Components/AllBanners";
import Appheader from "../Components/AppHeader";
import Footer from "../Components/Footer";
import ProfileCard from "../Components/ProfileCom/ProdileCard";
import RatingStar from "../Components/Rating/RatingStars";
import TopHeader from "../Components/TopHeader";
import useFetch from "../CustomHooks/UseFetch";
import { useNavigate, useParams } from "react-router-dom";
import AppLoader from "../Components/AppLoader";
import { useTranslation } from "react-i18next";
import { Pagination } from "antd";
import config from "../Services/Config.json";
import badgeimg from "../Assets/WebImages/badgeimage.png";
import noimage from "../Assets/WebImages/noimage.jpg";

function MerchanProfile() {
  const { id } = useParams();
  const [MerchantDetails, setMerchantDetails] = useState();
  const {
    data: merchantdata,
    loading,
    error,
  } = useFetch(`get-merchant-detail/${id}`);
  const [Isloading, setIsloading] = useState(true);
  const { t } = useTranslation();
  // pagination
  const [CurrentPage, setCurrentPage] = useState(1);
  const [CardListWithPag, setCardListWithPag] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    setIsloading(loading);
  }, [loading]);
  useEffect(() => {
    if (merchantdata) {
      console.log(merchantdata);
      setMerchantDetails(merchantdata?.data);
    }
  }, [merchantdata]);
  useEffect(() => {
    setIsloading(loading);
  }, [loading]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (Array.isArray(MerchantDetails?.services)) {
      if (MerchantDetails?.services?.length > 0) {
        const startIndex = (CurrentPage - 1) * 20;
        const endIndex = startIndex + 20;
        const currentData = MerchantDetails?.services?.slice(
          startIndex,
          endIndex
        );
        setCardListWithPag(currentData);
      } else {
      }
    }
  }, [CurrentPage, MerchantDetails]);
  const handleError = (e) => {
    console.log("object", e.target.src);
    e.target.src = noimage;
  };

  return (
    <>
      <AppLoader Isloader={Isloading} />

      <TopHeader />
      <Appheader />
      <section>
        <div className="MerchantProfileViewer">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 pb-sm-3 d-flex justify-content-center align-items-center">
                <div className="merchantprofile_IMGContainer">
                  <img
                    src={
                      MerchantDetails?.image ? MerchantDetails.image : noimage               
                    }
                    onError={handleError}
                  />
                  {MerchantDetails?.license_number ? (
                    <div className="licensebadge2">
                      <img src={badgeimg} alt="License Badge" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="merchantprofle_details">
                  <div className="merchantprofile_heading">
                    <h1>{MerchantDetails?.name}</h1>
                  </div>
                  <div className="merchantprofile_description">
                    <h4>
                      {t("Country")}: {MerchantDetails?.country}
                    </h4>
                    <div>
                      <p>{MerchantDetails?.about_us}</p>
                    </div>
                  </div>
                  {MerchantDetails?.services?.length > 0 &&
                    Array.isArray(MerchantDetails?.services) && (
                      <div className="merchantprofile_totall">
                        <h4>
                          {t("Total Services")}:{" "}
                          {MerchantDetails?.services?.length}
                        </h4>
                      </div>
                    )}
                  <div>
                    <h4>
                      {t("Rating")}: <RatingStar Star={5} />
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="merchantServicesCards text-center">
          <h1 className="text-center">{t("AllServices")}</h1>
          <div className="row p-0 m-0 justify-content-center align-item-center">
            {CardListWithPag?.length > 0 && Array.isArray(CardListWithPag) ? (
              CardListWithPag.map((item, index) => {
                return (
                  <div
                    className="col-md-3 col-sm-12 m-3 "
                    key={index}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate(`/${config.ddemoss}buyproduct/${item?.id}`);
                    }}
                  >
                    <div className="marchantservices_eachCard">
                      <div className="merchant_eachproductimg">
                        {/* <img src={item?.media_link?item?.media_link:noimage} alt={noimage} /> */}
                        <img
                          src={item?.media_link || noimage}
                          alt={item?.alt_text || "Image"}
                          onError={handleError}
                        />
                        <div class="marchantservices_eachCard_details">
                          <div>
                            <h2>
                              {item?.name.length > 20
                                ? item?.name.slice(0, 20) + "..."
                                : item?.name}
                            </h2>
                          </div>
                          <div>
                            <p>{item?.price} $</p>
                          </div>
                          <div>
                            <p>
                              {item?.description?.length > 100
                                ? item?.description?.slice(0, 100) + "....."
                                : item?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="d-flex align-items-centet justify-content-center mt-3">
                <p style={{ fontWeight: 600 }}>
                  {t("This merchant doesn't have any products.")}
                </p>
              </div>
            )}
          </div>
        </div>
        {MerchantDetails?.services?.length > 0 && (
          <Pagination
            className="text-center mt-3 text-success px-3 pg-cards"
            current={CurrentPage}
            total={MerchantDetails?.services?.length}
            pageSize={20}
            onChange={handlePageChange}
          />
        )}
      </section>
      <Footer />
    </>
  );
}

export default MerchanProfile;
