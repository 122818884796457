import { Route, Routes } from "react-router-dom";
import Home from "../Containers/Home/Home";
import Login from "../Containers/Login";
import Signup from "../Containers/Signup";
import Verification from "../Containers/Verification";
import Acountcreate from "../Containers/Acountcreate";
import AboutUs from "../Containers/AboutUs";
import ChangeEmail from "../Containers/ChangeEmail";
import ForgotPassword from "../Containers/ForgotPassword";
import ContactUs from "../Containers/ContactUs";
import Category from "../Components/Category";
import AddProduct from "../Containers/ProductProfile";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import Rating from "../Components/Rating/Rating";
import UserProfile from "../Containers/UserProfile";
import ChangePassword from "../Containers/ChangePassword";
import UserEditProfile from "../Containers/UserEditProfile";
import IndetifyPass from "../Containers/PassIdentify";
import BuyProduct from "../Components/Profile/BuyProduct";
import useFetch from "../CustomHooks/UseFetch";
import { useContext, useEffect } from "react";
import { mainContentTextProdvider } from "../Context/ContentText";
import { getIdSession, getTokenSession } from "../MainFunction";
import axios from "axios";
import config from ".././Services/Config.json"
import { MainUsersContext } from "../Context/UserContext";
import MerchanProfile from "../Containers/MerchantProfile";
import Favourite from "../Containers/Favourite";
import Favorite from "../Containers/Favorite";
import Notification from "../Containers/Notification";
import PaySubscription from "../Containers/PaySubscription";
import AddCard from "../Containers/AddCard";


function AppRouter() {
  const { data: footer_content, loading, error } = useFetch("footer-content");
  const { setContent } = useContext(mainContentTextProdvider);
  const { HandleUserCredential, UserCredential } = useContext(MainUsersContext);

  const authID = getIdSession()
  const authentication = getTokenSession();

  useEffect(() => {
    if (footer_content) {
      setContent(footer_content?.data)
    }
  }, [footer_content]);

  useEffect(() => {
    if (authID && authID != undefined && authID != null && authID != "") {
      axios.get(`${config.apiEndPoint}get-user-detail/${authID}`, {
        headers: {
          Authorization: `Bearer ${authentication}`,
        },
      })
        .then((response) => {
          if (response?.data?.data) {
            HandleUserCredential(response?.data?.data.user, response?.data?.data.token)
          }
        })
        .catch((error) => {
          // console.log(error, "error");
        });
    }
  }, [authID]);


  return (
    <>
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/identify" element={<IndetifyPass />} />
        <Route path="/accountcreate" element={<Acountcreate />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/category/:catname?/:idcat?/:subname?/:idsub?" element={<Category />} />
        <Route path="/buyproduct/:id" element={<BuyProduct />} />
        {/* <Route path="/profilehome" element={<Profile />} /> */}
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/changeemail" element={<ChangeEmail />} />
        <Route path="/Favourite" element={<Favourite />} />

        <Route path="/addproduct" element={<AddProduct />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/editprofile" element={<UserEditProfile />} />
        <Route path="/merchantprofile/:id" element={<MerchanProfile />} />
        <Route path="/rating/:id" element={<Rating />} />
        {/* <Route path="/favorite" element={<Favorite />} /> */}
        <Route path="/notification" element={<Notification />} />
        <Route path="/paysubscription" element={<PaySubscription />} />
        {/* <Route path="/addcard" element={<AddCard />} /> */}
      </Routes>
    </>
  );
}

export default AppRouter;
