import React, { useEffect } from "react";
import PeopleComment from "./PeopleComment";
import { useTranslation } from "react-i18next";
import Aboutme from "./Aboutme";
import config from "../../Services/Config.json"
import { useNavigate } from "react-router-dom";

function RecommendedBanner({ RecommendedData }) {
  const { t } = useTranslation();
  const navigate = useNavigate()

  return (
    <div className="Service_banner container mt-5">
      {RecommendedData?.data?.recommended_services?.length > 0 && Array.isArray(RecommendedData?.data?.recommended_services) &&
        <>
          <h3 className="ps-3">{t("Recommended")}</h3>
          <div className="row  recommanded_product_cards p-0 m-0">
            {Array.isArray(RecommendedData?.data?.recommended_services) && RecommendedData?.data?.recommended_services.map((item, index) => (
              <div className=" col-sm-12 col-md-5  col-lg-4">
                   <div key={index} className="recommanded_product mt-3" onClick={() => navigate(`/${config.ddemoss}buyproduct/${item?.id}`)}>
                <div className="recommanded_products_img">
                  <img src={item?.media_link} alt={"productimg"} />
                </div>
                <div className="recommanded_products_name">
                  <p>
                    {item?.name}
                  </p>
                </div>
              </div>
              </div>
            ))}
          </div>
        </>
      }
      {RecommendedData?.data?.review_services?.length > 0 && < PeopleComment RecommendedData_comments={RecommendedData && RecommendedData} />}
      {RecommendedData?.data?.service?.user?.about_us && < Aboutme Merchantinfo={RecommendedData?.data?.service?.user?.about_us} />}
    </div>
  );
}

export default RecommendedBanner;
