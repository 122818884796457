import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import star from "../../Assets/WebImages/star.png";
import whatsapp from "../../Assets/WebImages/whatsapp.png";
import insta from "../../Assets/WebImages/insta.png";
import heart from "../../Assets/WebImages/heart.png";
import api from "../../Services/httpService";
import { getTokenSession } from '../../MainFunction';

const VerifyUserForShare = ({ type, UserCredential, MerchantMedia, id, config, t }) => {
    const navigate = useNavigate();
    const [imageSrc, setImageSrc] = useState(null);
    const [isFavorite, setIsFavorite] = useState(false);
    // console.log(t);
    const handleSocialMediaClick = () => {

        if (!UserCredential?.id) {
            navigate('/login');
            return;
        }
            // console.log(MerchantMedia?.instagram);
        switch (type) {
            case 'Instagram' || "انستغرام":
                if (MerchantMedia?.instagram) {
                    
                    window.open(`${MerchantMedia.instagram?MerchantMedia?.instagram:'https://www.instagram.com'}`, '_blank'); // Open in new tab
                }
                break;
            case 'Rating' || "تقييم":
                navigate(`/${config.ddemoss}rating/${id}`);
                break;
            case 'Whatsapp' || "واتس اب":
                if (MerchantMedia?.whatsapp) {
                    window.open(`https://api.whatsapp.com/send?phone=${MerchantMedia.whatsapp}`, '_blank'); // Open in new tab
                }
                break;
            case 'Addtofavourite':
                api.post(
                    `${config.apiEndPoint}wishlists`,
                    { vendor_id: MerchantMedia.id, is_like: isFavorite ? 0 : 1 },
                    {
                        headers: {
                            Authorization: `Bearer ${UserCredential.token}`
                        }
                    }
                ).then(() => {
                    setIsFavorite((prevState) => !prevState);
                    setImageSrc(heart);
                });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const fetchFavorites = async () => {
            const data = await api.get(
                `${config.apiEndPoint}wishlists`,
                {
                    headers: {
                        Authorization: `Bearer ${getTokenSession()}`
                    }
                }
            );

            for (let merchant of data.data.data) {
                if (merchant.vendor.id === MerchantMedia.id) {
                    setIsFavorite((prevState) => !prevState);
                    setImageSrc(heart);
                    return;
                }
            }
            setImageSrc(getDefaultImage(type));
        };

        if (type === 'Addtofavourite') {
            fetchFavorites();
        } else {
            setImageSrc(getDefaultImage(type));
        }
    }, [MerchantMedia.id, config.apiEndPoint, type]);

    const getDefaultImage = (type) => {
        switch (type) {
            case 'Instagram':
                return insta;
            case 'Rating':
                return star;
            case 'Whatsapp':
                return whatsapp;
            case 'Addtofavourite':
                return heart;
            default:
                return null;
        }
    };

    return (
        <div className="social_Media" onClick={handleSocialMediaClick}>
            {isFavorite ? (
                <img src={require('../../Assets/WebImages/heart.png')} alt={t(type)} />
            ) : (
                <img src={imageSrc} alt={type} />
            )}
            <p className="text-light ">{t(type)}</p>
        </div>
    );
};

export default VerifyUserForShare;
