import { useNavigate } from "react-router-dom";
import AllBanners from "../Components/AllBanners";
import Allcards from "../Components/Allcards";
import DrawerAppBar from "../Components/AppHeader";
import Footer from "../Components/Footer";
import TopHeader from "../Components/TopHeader";
import { MainUsersContext } from '../Context/UserContext'
import { useContext, useEffect } from "react";
import config from "../Services/Config.json"

function ForgotPassword() {
    const { UserCredential } = useContext(MainUsersContext);
    const navigate = useNavigate()

    useEffect(() => {
        if (UserCredential?.id) {
            navigate(`/${config.ddemoss}`);
        }
    }, [UserCredential]);


    return (
        <div>
            <TopHeader />
            <DrawerAppBar />
            <AllBanners title={"forgotPassword"} />
            <div className='Card_container'>
                <Allcards forpage={"Forgot Password"} />
            </div>
            <Footer />
        </div>
    );
}

export default ForgotPassword;