import React, { useContext, useEffect, useState } from "react";
import TopHeader from "../Components/TopHeader";
import AppHeader from "../Components/AppHeader";
import AllBanners from "../Components/AllBanners";
import Aboutus from "../Assets/WebImages/AboutUs.png";
import Aboutus2 from "../Assets/WebImages/Aboutus2.png";
import Aboutus3 from "../Assets/WebImages/Aboutus3.png";
import Aboutus4 from "../Assets/WebImages/Aboutus4.png";
import Aboutus5 from "../Assets/WebImages/Aboutus5.png";
import Footer from "../Components/Footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import useFetch from "../CustomHooks/UseFetch";
import usePost from "../CustomHooks/UsePost";
import { toast } from "react-hot-toast";
import HomeSlider from "../Components/HomeSlider";
import AboutusSlider from "../Components/AboutUsSlider";
import { languageContextProvider } from "../Context/LangaugeContext";
import AppLoader from "../Components/AppLoader";

gsap.registerPlugin(ScrollTrigger);

function AboutUs() {
  const { t } = useTranslation();
  const { data: aboutcontent, loading, error } = useFetch("about-content");
  const { handleLanguageChange_Context, langName } = useContext(languageContextProvider);
  const [Isloading, setIsloading] = useState(true);
  const [Disabled, setDisabled] = useState(false);


  const [res, apiMethod] = usePost();
  const [Email, setEmail] = useState();


  useEffect(() => {
    setIsloading(loading)
  }, [loading]);
  const data = [
    {
      id: 1,
      img: Aboutus2,
      name: "John Peter",
      title: "COO",
    },
    {
      id: 2,
      img: Aboutus3,
      name: "John Peter",
      title: "COO",
    },
    {
      id: 3,
      img: Aboutus4,
      name: "John Peter",
      title: "COO",
    },
    {
      id: 4,
      img: Aboutus5,
      name: "John Peter",
      title: "COO",
    },
  ];


  useEffect(() => {
    gsap.set(".BackGround, .aboutus_content", { opacity: 0, x: 100 });

    ScrollTrigger.create({
      trigger: ".BackGround",
      start: "top 80%",
      onEnter: () => {
        gsap.to(".BackGround, .aboutus_content", {
          opacity: 1,
          x: 0,
          duration: 0.9,
          ease: "power1.out",
        });
      },
      once: true,
    });
  }, []);


 
  const HandleSubscribe = () => {
    if (Email?.trim()) {
      let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (regex.test(Email)) {
        setDisabled(true)
        const formData = new FormData();
        formData.append("email", Email);
        apiMethod("subcribe-email", formData);
      }
    }
  }

  useEffect(() => {
    if (res?.data?.message) {
      setEmail("")
      setDisabled(false)

      toast.success(t('Subsribe Successfully!'), {
        icon: '👏',
        duration: 5000,
        position: "bottom-right",
        style: {
          background: '#75c936',
          color: "#fff"
        },
      });
    }
  }, [res?.data]);

  return (
    <>
      <AppLoader Isloader={Isloading} />
      <div className="about_US ">
        <TopHeader />
        <AppHeader />
        <AllBanners title={"About Us"} />
        <div className="BackGround">
          <div className="container py-4  ">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12 mb-2 ">
              <div className="about_imgdiv">
              <img  className="about_img " src={Aboutus} alt="" height={"100%"} width={"100%"} />
              </div>
              </div>
              <div className="col-lg-6  col-md-6  col-xs-12 my-auto aboutus_content">
                {/* 
              <p className="p">Lorem Ispum</p>
              <h3>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </h3>
              <h6 className="mt-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                took a galley of type and scrambled it to make a type specimen book. It
                has survived not only five centuries,
              </h6> */}
                <div className="aboutuspara" dangerouslySetInnerHTML={{ __html: aboutcontent && langName == "arabic" ? aboutcontent?.data?.arabic_about_content : aboutcontent?.data?.about_content }} />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mt-5 BackGround_Green">
            <h5 style={{ fontSize: "28px", color: "white" }}> {t("StayInLoop")}</h5>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <p style={{ marginTop: 20 }}>
                  {t("aboutuspara")}
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-xs-12">
                <div className="about_inpt_main">
                  <div className=" about_inp">
                    <input
                      type="text"
                      value={Email}
                      className="p-2 about-input"
                      placeholder={t("EmailAddress")}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button className='aboutusbtn' disabled={Disabled} variant="contained" size="medium" onClick={HandleSubscribe}   >
                      {t("continue")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AboutUs;
