import React, { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Select } from 'antd';
import { Dropdown, Menu } from 'antd';
import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import useFetch from "../CustomHooks/UseFetch";
import { useNavigate } from "react-router-dom";
import config from "../Services/Config.json"
import { MdArrowDropDown } from "react-icons/md";
import { languageContextProvider } from "../Context/LangaugeContext";


function CategoriesBanner({ data }) {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [CategoryListData, setCategoryListData] = useState();
  const { langName } = useContext(languageContextProvider);

  useEffect(() => {
    if (data?.length > 0 && Array.isArray(data)) {
      setCategoryListData(data.slice(0, 5))
    }
  }, [data]);

  // const HandleCategories = (item, subcatID, subName) => {
  //   navigate(`/${config.ddemoss}category/${item?.name}/${item?.id}/${subName?.children}/${subcatID}`)
  // }
  const HandleCategories = (item, subID, subCatname) => {
    navigate(`/${config.ddemoss}category/${item?.name}/${item?.id}/${subCatname.toLowerCase()}/${subID?.key}`)
  }



  return (
    <div className="CategoriesBanner">
      <div className="container category_heading">
        <h5>{t("categories")}</h5>
        <h1 style={{ textTransform: "uppercase" }} >{t("popularcategories")}</h1>
      </div>
      <div className="container category_cardMain mt-3">
        <div className="category_Cards_container">
          {CategoryListData && CategoryListData?.map((item, index) => (
            <motion.div key={index} className={index === 0 ? "categorydiv_one_box1 home_categoryeach" : index === 1 ? "categorydiv_one_box2 home_categoryeach" : index === 2 ? "categorydiv_one_box3 home_categoryeach" : "home_categoryeach cat-45-home"} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: index * 0.2 }}>
              <div className="Home_categoriesimg">
                <img src={item?.media_link} />
              </div>
              <div className="home_popolar_cat">
                <h5>
                  {langName == "arabic" ? item?.arabic_name : item?.name}
                </h5>
                {/* <Select
                  className="cat_select"
                  showSearch
                  placeholder="Search subcategory"
                  onChange={(selectedSubcategoryId, subname) =>
                    HandleCategories(item, selectedSubcategoryId, subname)
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                >
                  {item?.subcategories.map(subcategory => (
                    <Select.Option key={subcategory?.id} value={subcategory?.id}>
                      {subcategory?.name}
                    </Select.Option>
                  ))}
                </Select> */}

                <Dropdown
                  overlay={
                    <Menu onClick={(e) => HandleCategories(item, e, e.domEvent.currentTarget.textContent)}>
                      {item?.subcategories.map(subcategory => (
                        <Menu.Item key={subcategory?.id}>
                          {langName == "arabic" ? subcategory?.arabic_name : subcategory?.name}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  trigger={['click']}
                  placement="bottomRight"
                  arrow
                >
                  <div className="home_subcat_search">
                    <span>
                      {t("selectsubcategory")}
                    </span>
                    <MdArrowDropDown size={18} />
                  </div>
                </Dropdown>
              </div>
            </motion.div>
          ))}

        </div>
      </div>
      <div className="text-center">
        <Button
          className="mb-2 mt-3 button"
          variant="h6"
          onClick={() => navigate(`/${config.ddemoss}category`)}
        >
          {t("viewmore")}
        </Button>
      </div>
    </div >
  );
}

export default CategoriesBanner;
