import React, { useContext, useEffect, useState } from "react";
import TopHeader from "./TopHeader";
import AllBanners from "./AllBanners";
import { Button, Divider, IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Pagination, Select } from "antd";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import "aos/dist/aos.css";
import useFetch from "../CustomHooks/UseFetch";
import axios from "axios";
import { getTokenSession } from "../MainFunction";
import config from ".././Services/Config.json";
import { useNavigate, useParams } from "react-router-dom";
import DrawerAppBar from "./AppHeader";
import usePost from "../CustomHooks/UsePost";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { languageContextProvider } from "../Context/LangaugeContext";
import AppLoader from "./AppLoader";
import { SyncLoader, PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
gsap.registerPlugin(ScrollTrigger);

function Category() {
  const { t } = useTranslation();
  let { idcat, idsub, catname, subname } = useParams();
  const { langName } = useContext(languageContextProvider);
  // console.log(idcat);
  const navigate = useNavigate();
  const { data: categories, loading, error } = useFetch("categories");
  const [res, apiMethod] = usePost();

  const endpoint =
    idcat !== undefined && idsub !== undefined
      ? `service/all/${idcat}/${idsub}`
      : idcat !== undefined || idsub !== undefined
      ? `searchCategories/${idcat}`
      : "service/all";
  const { data: ServiceAll, loading2, error2 } = useFetch(endpoint);

  const {
    data: directSubCategory,
    loading: loading3,
    error: error3,
  } = useFetch(idcat && idsub === undefined ? `subcategories/${idcat}` : null);

  const [SubCategoriesList, setSubCategoriesList] = useState([]);
  const [showholder,setShowholder]=useState(false)
  const [CardsListData, setCardsListData] = useState();
  const [Category_ID, setCategory_ID] = useState(
    idcat !== undefined && Number(idcat)
  );
  const [SubCategoryId, setSubCategoryId] = useState(
    idsub !== undefined && Number(idsub)
  );
  const [ISLoader, setISLoader] = useState(true);
  // pagination stats
  const [CurrentPage, setCurrentPage] = useState(1);
  const [CardListWithPag, setCardListWithPag] = useState();
  const itemsPerPage = 8;
  // searching state
  const [SerachingData, setSerachingData] = useState();
  const authentication = getTokenSession();
  // loader state
  const [Isloading, setIsloading] = useState(true);
  const [NoResultFound, setNoResultFound] = useState(false);

  useEffect(() => {
    const response = loading || loading2;
    setIsloading(response);
  }, [loading, loading2]);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  useEffect(() => {
    if (ServiceAll?.data && Array.isArray(ServiceAll?.data)) {
      setCardsListData(ServiceAll?.data);
      const subCategoriesList = ServiceAll?.subcategories?.map((category) => ({
        label: langName == "arabic" ? category?.arabic_name : category?.name,
        value: category?.id,
      }));
      setSubCategoriesList(subCategoriesList);
      // setCategory_ID(idcat && idcat != undefined && idcat != null && idcat)
    }
  }, [ServiceAll]);

  useEffect(() => {
    if (
      directSubCategory?.data?.length > 0 &&
      Array.isArray(directSubCategory?.data)
    ) {
      const subCategoriesList = directSubCategory?.data?.map((category) => ({
        label: langName == "arabic" ? category?.arabic_name : category?.name,
        value: category?.id,
      }));
      setSubCategoriesList(subCategoriesList);
    }
  }, [directSubCategory]);

  useEffect(() => {
    if (Array.isArray(CardsListData)) {
      if (CardsListData.length > 0) {
        const startIndex = (CurrentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const currentData = CardsListData?.slice(startIndex, endIndex);
        setCardListWithPag(currentData);
        setNoResultFound(false);
      } else {
        setNoResultFound(true);
      }
    }
  }, [CardsListData, CurrentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const option =
    categories?.length > 0 &&
    Array.isArray(categories) &&
    categories.map((category) => ({
      label: langName == "arabic" ? category.arabic_name : category.name,
      value: category.id,
    }));

  const handleChangeCategory = async (value) => {
    setISLoader(true);
    if (!value) {
      return;
    }
    try {
      setShowholder(true)
      setCategory_ID(value);
      setSubCategoryId(null);
      const headers = {
        Authorization: `Bearer ${authentication}`,
      };
      const categoriesResponse = await axios.get(
        `${config.apiEndPoint}searchCategories/${value}`,
        { headers }
      );
      const categoriesData = categoriesResponse?.data?.data || [];
      setISLoader(false);
      setCardsListData(categoriesData);
      setCurrentPage(1);

      const subcategoriesResponse = await axios.get(
        `${config.apiEndPoint}subcategories/${value}`,
        { headers }
      );
      const subcategoriesData = subcategoriesResponse?.data?.data || [];

      const subCategoriesList = subcategoriesData?.map((category) => ({
        label: langName == "arabic" ? category.arabic_name : category.name,
        value: category?.id,
      }));

      setSubCategoriesList(subCategoriesList);
    } catch (error) {
      // console.log(error, "error");
      setISLoader(false);
    }
  };

  const handleChangeSubt_Category = (value) => {
    setISLoader(true);
    setSubCategoryId(value);
    if (value) {
      axios
        .get(
          `${config.apiEndPoint}searchSubCategories/${Category_ID}/${value}`,
          {
            headers: {
              Authorization: `Bearer ${authentication}`,
            },
          }
        )
        .then((response) => {
          setISLoader(false);
          // console.log(response);
          if (Array.isArray(response?.data?.data)) {
            setCardsListData(response?.data?.data);
            setCurrentPage(1);
          }
        })
        .catch((error) => {
          setISLoader(false);
          // console.log(error, "error");
        });
    }
  };

  useEffect(() => {
    if (!loading && !loading2) {
      setISLoader(false);
    }
  }, [categories, ServiceAll]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const HandleSearching = (searching) => {
    setSerachingData(searching.target.value);
  };
  const HandleSearchingSubmit = (event) => {
    event.preventDefault();
    if (
      SerachingData?.trim() &&
      SerachingData != null &&
      SerachingData != undefined
    ) {
      const formData = new FormData();
      if (Category_ID && SerachingData && SubCategoryId) {
        formData.append("search_term", SerachingData);
        formData.append("categoryId", Category_ID);
        formData.append("subcategoryId", SubCategoryId);
      } else if (SerachingData && Category_ID) {
        formData.append("search_term", SerachingData);
        formData.append("categoryId", Category_ID);
      } else {
        formData.append("search_term", SerachingData);
      }
      apiMethod("services/search", formData);
    }
  };
  useEffect(() => {
    if (res?.data) {
      if (Array.isArray(res?.data?.data)) {
        setCardsListData(res?.data?.data);
        setCurrentPage(1);
      }
    }
  }, [res?.data]);

  useEffect(() => {
    gsap.set(".backgroundtwo-cards", {
      opacity: 0,
      scale: 0.5,
      transformOrigin: "center center",
    });

    const startAnimation = () => {
      gsap.to(".backgroundtwo-cards", {
        opacity: 1,
        scale: 1,
        duration: 0.9,
        ease: "power3.out",
      });
    };
    setTimeout(() => {
      startAnimation();
    }, 3000);
  }, []);

  useEffect(() => {
    gsap.set(".categoryOne", { opacity: 0, x: -100 });

    ScrollTrigger.create({
      trigger: ".categoryOne",
      start: "top 80%",
      onEnter: () => {
        gsap.to(".categoryOne", {
          opacity: 1,
          x: 0,
          duration: 0.9,
          ease: "power1.out",
        });
      },
      once: true,
    });
  }, []);

  return (
    <div className="category ">
      <AppLoader Isloader={Isloading} />

      <TopHeader />
      <DrawerAppBar />
      <AllBanners title={"Category"} />

      <div className="container mt-5">
        <div className="row p-0 m-0">
          <div className="categoryOne col-md-4 col-sm-12">
            <Paper
              className="   "
              component="form"
              onSubmit={HandleSearchingSubmit}
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{ p: "10px" }}
                aria-label="menu"
                onClick={HandleSearchingSubmit}
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t("Search")}
                inputProps={{ "aria-label": "search " }}
                onChange={HandleSearching}
              />

              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            </Paper>
            <div className="Category_search_cat">
              
             {
              Category_ID?(''):(
                <p style={{position:"absolute",left:"5px",bottom:"10px",color:"white",zIndex:"1"}}>{t("Select Category")}</p>
              )
             }
              <Select
                defaultValue={idcat && Number(idcat)}
                value={Category_ID}
                // showSearch
                optionFilterProp="children"
                className="mt-3 cat-input"
                // placeholder={t("Select Category")}
                placeholder="Select Category"
                filterOption={filterOption}
                onChange={handleChangeCategory}
                options={option}
              />
      
            </div>
            {/* {console.log(idsub)} */}
            <div className="Category_search_Sub_cat">
              <Select
                defaultValue={idsub && Number(idsub)}
                value={SubCategoryId}
                // showSearch
                className="mt-3 cat-input"
                optionFilterProp="children"
                placeholder={t("SelectSubCategory")}
                filterOption={filterOption}
                onChange={handleChangeSubt_Category}
                options={Array.isArray(SubCategoriesList) && SubCategoriesList}
                notFoundContent={
                  <div style={{ textAlign: "center" }}>
                    {t("No data found")}
                  </div>
                } // Centered custom message here
              />
            </div>
            {/* <div className="mt-3 d-flex flex-wrap justify-content-center gap-2">
              <Button
                className="button1"

                variant="h6"
              >
                {t("Chocalates")}
              </Button>
              <Button
                className="button2"
                variant="h6"
              >
                {t("Savory")}
              </Button>
              <Button
                className="button2"
                variant="h6"
              >
                {t("Others")}
              </Button>
            </div> */}
          </div>
          {ISLoader ? (
            <div className="fetchingLoader col-md-8 col-sm-12">
              {/* <img src={require("../Assets/WebImages/ajax-loader.gif")} alt="" /> */}

              <SyncLoader
                color={"#2B9229"}
                loading={true}
                cssOverride={override}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : NoResultFound ? (
            <div className="fetchingLoader col-md-8 col-sm-12">
              {/* <img src={require("../Assets/WebImages/ajax-loader.gif")} alt="" /> */}

              <PropagateLoader
                color={"#2B9229"}
                loading={true}
                cssOverride={override}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="ms-1"
              />
              <p>{t("No Service Found 404")}</p>
            </div>
          ) : (
            <div className="categoryTwo  col-md-8 col-sm-12">
              {CardListWithPag &&
                Array.isArray(CardListWithPag) &&
                CardListWithPag?.map((item, index) => (
                  // <div key={index} className="backgroundtwo-cards  mb-2" onClick={() => navigate(`/${config.ddemoss}category/${item?.name}/${item?.id}`)}>
                  <div
                    key={index}
                    className="backgroundtwo-cards  mb-2"
                    onClick={() =>
                      navigate(`/${config.ddemoss}buyproduct/${item?.id}`)
                    }
                  >
                    <div className="categorycardsimg">
                      <img src={item?.media_link} />
                    </div>
                    <div className="d-flex justify-content-center flex-column align-items-center p-2">
                      <span
                        style={{
                          borderTop: "1px solid #E0E2EE",
                          width: "100px",
                          marginTop: "19px",
                          marginBottom: "14px",
                        }}
                      ></span>
                      <p style={{ fontWeight: "bold" }} className="mb-1">{item?.name}</p>
                      <p
                        style={{
                          color: "#2b9229",
                          fontSize: "13px",
                          marginBottom: "5px",
                          wordBreak: "break-word",
                        }}
                      >
                        {item?.category?.name}
                      </p>
                      <Button
                        className="mb-2 button"
                        sx={{
                          backgroundColor: "#418E30",
                          color: "white",
                          borderRadius: 8,
                          // padding: "px",
                          fontSize: "12px",
                          textAlign: "center",
                          "&:hover": {
                            backgroundColor: "#418E30",
                          },
                        }}
                        variant="h6"
                      >
                        {t("viewmore")}
                      </Button>
                    </div>
                  </div>
                ))}
              {CardsListData?.length >= 8 && (
                <Pagination
                  className="text-center mt-3 text-success px-3 pg-cards"
                  current={CurrentPage}
                  total={CardsListData && CardsListData?.length}
                  pageSize={itemsPerPage}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {/* <h6 className="text-center mt-3 text-success px-3"><IoIosArrowBack className="mx-2" />1 2 3 4<IoIosArrowForward className="mx-2" />  </h6> */}

      <Footer />
    </div>
  );
}

export default Category;
