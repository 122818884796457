import React, { useEffect } from "react";
import FeaturesPic1 from "../Assets/WebImages/FeaturesPic1.png";
import FeaturesPic2 from "../Assets/WebImages/FeaturesPic2.png";
import FeaturesPic3 from "../Assets/WebImages/FeaturesPic3.png";
import { Button } from "@mui/material";
import { motion } from "framer-motion";
import { FaArrowRight, FaStar } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import RatingStar from "./Rating/RatingStars";
import { useNavigate } from "react-router-dom";
import config from "../Services/Config.json";

function FeatureProviderBanner({ data }) {
  // const data = [
  //   {
  //     id: 1,
  //     name: "Stuart Clark",
  //     img: FeaturesPic1,
  //     Services: "845",
  //     minute: "30",
  //     para: `Lorem Ipsum is simply dummy text of the printing and typesetting
  //   industry. Lorem Ipsum is simply dummy text of the printing and
  //   typesetting industry.`,
  //   },
  //   {
  //     id: 2,
  //     name: "Peter Parker",
  //     img: FeaturesPic2,
  //     Services: "12",
  //     minute: "55",
  //     para: `Lorem Ipsum is simply dummy text of the printing and typesetting
  //   industry. Lorem Ipsum is simply dummy text of the printing and
  //   typesetting industry.`,
  //   },
  //   {
  //     id: 3,
  //     name: "David Miller",
  //     img: FeaturesPic3,
  //     Services: "56",
  //     minute: "45",
  //     para: `Lorem Ipsum is simply dummy text of the printing and typesetting
  //   industry. Lorem Ipsum is simply dummy text of the printing and
  //   typesetting industry.`,
  //   },
  // ];
  const { t } = useTranslation()

  const navigate = useNavigate()
  return (

    <div className="container">
      <div >
        <div className="text-center mt-5  mb-5">
          <h6 className="heading1">{t("vendor")}</h6>
          <h2 className="text-black heading2">{t("featuredProviders")}</h2>
        </div>
      </div>
      <div className="Featured_Cards">
        {data?.data?.providers && Array.isArray(data?.data?.providers) && data?.data?.providers?.slice(0, 4)?.map((item, index) => (
          <div key={index} className="Featured_Cards_each">
            <div className="Featured_Cards_name_container">
              <h4>{item?.name}</h4>
              <div className="Featured_Cards_merchantprofilebox">
                <div className="Featured_Cards_merchantimg">
                  <img src={item?.image} alt="" />
                </div>
              </div>
            </div>
            <div className="Featured_Cards_each_body">
              <div className="Featured_Cards_merchant_descript">
                <div>
                  <h2>{item?.services_count}</h2>
                  <span  onClick={() => navigate(`/${config.ddemoss}merchantprofile/${item?.id}`)} style={{cursor:"pointer"}}>{t("services")}</span>
                </div>
                <div>
                  <p>
                    <RatingStar Star={item?.average_rating} />
                  </p>
                  <span>{t("rating")} {item?.rating_count}</span>
                </div>
                <div>
                  <h2>{index === 1 ? "32" : index === 2 ? "52" : "44"}</h2>
                  <span>{t("minutes")}</span>
                </div>
              </div>
              <div className="Featured_Cards_merchant_bio">
                <span>
                  {item?.about_us?.slice(0, 100)}
                  {item?.about_us?.length > 100 ? "....." : ""}
                </span>
              </div>
            </div>
            <div className="view-btn">
              <Button className="mt-auto button" variant="h6" endIcon={<FaArrowRight />} onClick={() => navigate(`/${config.ddemoss}merchantprofile/${item?.id}`)}>
                {t("ReadMore")}
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeatureProviderBanner;
