import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import aos from 'aos';
import "aos/dist/aos.css"

function Aboutme({ Merchantinfo }) {
  const { t } = useTranslation();

  return (
    <div className='About_me mt-5 mb-5' >
      <h2>{t("About me")}</h2>
      <p>{t(Merchantinfo)}</p>
    </div>
  )
}

export default Aboutme
