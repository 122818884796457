import { useContext, useEffect } from "react";
import AllBanners from "../Components/AllBanners";
import Appheader from "../Components/AppHeader";
import Footer from "../Components/Footer";
import ProfileCard from "../Components/ProfileCom/ProdileCard";
import TopHeader from "../Components/TopHeader";
import { MainUsersContext } from "../Context/UserContext";
import { useNavigate } from "react-router-dom";
import { getTokenSession } from "../MainFunction";
import config from "../Services/Config.json"


function UserEditProfile() {
    const { UserCredential } = useContext(MainUsersContext);
    const navigate = useNavigate()

    const authentication = getTokenSession();
    useEffect(() => {
        if (UserCredential == undefined && (authentication == null || !authentication || UserCredential?.id)) {
            navigate(`/${config.ddemoss}login`);
        }
    }, [UserCredential]);
    return (
        <>
            <TopHeader />
            <Appheader />
            <AllBanners title={"Edit Profile"} />
            <ProfileCard title={"editprofile"} />

            <Footer />
        </>
    );
}

export default UserEditProfile;