import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import useFetch from "../CustomHooks/UseFetch";
import { Link, useNavigate } from "react-router-dom";
import config from "../Services/Config.json"
import Slider from "react-slick";
import { GrFormNextLink } from "react-icons/gr";
import { IoArrowBackOutline } from "react-icons/io5";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
function Advertisement() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [homeImage,sethomeImage]=useState()
    // Advertisement banner api 
    // const { data: new_arrivals, loading, error } = useFetch("service/new_arrivals");

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: true,
        // autoplaySpeed: 2000,
    };

    // const Data = [
    //     {
    //         img: "https://media.istockphoto.com/id/1217108207/photo/shopping-or-delivery-fruits-and-vegetables.jpg?s=612x612&w=0&k=20&c=Fhd4ySZl4lwVoHCPcpOO-UOldzTUYPVEHaNc2cYp3KI="
    //     },
    //     {
    //         img: "http://localhost:3000/static/media/testing1.d35e2d076c09c1917013.png"
    //     },
    //     {
    //         img: "https://media.istockphoto.com/id/1416417320/photo/healthy-food-healthy-eating-background-salmon-fruit-vegetable-and-berry-superfood.jpg?s=612x612&w=0&k=20&c=m919a3bU3MLLc9B1arqd5BSAJ6jx08sconxGNJVUA9E="
    //     },
    // ]
    useEffect(() => {
        async function fetchImage() {
          try {
            const response = await axios.get(`${config.apiEndPoint}app-banner`);
            // console.log(response?.data?.data?.Banner);
            sethomeImage(response?.data?.data.Banner)
          } catch (error) {
            console.error('Error fetching image:', error);
          }
        }
      
        fetchImage();
      }, []);

    return (
        <>
            <div className="container">
                <div className="Advertisements-container ">
                    <h1>
                        {t("Advertisements")}
                    </h1>
                </div>
            </div>
            <div className=" advertisements-con mb-5">
                <div className="Advertisements-container-slider">
                    <Slider {...settings}>
                        {homeImage?.map((item, index) => {
                            return (
                                <Link key={index} className="h-100" to={item?.url}>
                                    <div key={index} className="w-100" >
                                        {/* {console.log(`${item?.image_url}`)} */}
                                        <img className="" src={`${item?.image_url}`} alt="banner" />
                                    </div>
                                </Link >
                            )
                        })}
                    </Slider>
                </div>
            </div>
        </>
    );
}

export default Advertisement;