import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    resources: {
        english: {
            translation: {
                "Pay Subscription":"Pay Subscription",
                "Favorite":"Favorite",
                "Change Password":"Change Password",
                "Change Email":"Change Email",
                "Settings":"SETTINGS",
                "SETTINGS":"SETTINGS",
                "Licence Number":"Licence Number",
                "forgotPassword": "Forgot Password",
                "home": "Home",
                "aboutUs": "About Us",
                "category": "Category",
                "contactus": "Contact Us",
                "profile": "Profile",
                "addProduct": "Add Product",
                "ReadMore": "Read More ",
                "login": "LOGIN",
                "signup": "SIGN UP",
                "logout": "Log Out",
                "categories": "Categories",
                "popularcategories": "Popular Categories",
                "selectsubcategory": "Select subcategory",
                "products": "Products",
                "newarrival": "New Arrival",
                "viewmore": "View More",
                "whychooseus": "WHY CHOOSE US",
                "vendor": "VENDOR",
                "featuredProviders": "FEATURED PROVIDERS",
                "services": "Services",
                "rating": "Rating",
                "minutes": "Minutes",
                "OurServices": "Our Services",
                "Renting": "Renting",
                "Consultation": "Consultation",
                "Financial&realstates": "Financial & Real Estate Services",
                "Designandadvertisement": "Design and Advertisement",
                "Technologyservices": "Technology Services",

                "Newsletter": "Newsletter",
                "Subscribe us for latest update.": "Subscribe us for the latest updates.",
                "Enter your email": "Enter your email",
                "OurTeam": "Our Team",
                "StayInLoop": "Stay In Loop",
                "aboutuspara": "Want to stay updated effortlessly? Sign up with your email to receive our latest news and updates",
                "EmailAddress": "Email Address",
                "This merchant doesn't have any products.": "This merchant doesn't have any products.",
                "continue": "Continue",
                "Search": "Search",
                "Price": "Price",
                "Qunatity": "Qunatity",
                "Chocalates": "Chocalates",
                "Savory": "Savory",
                "Administration": "Administration",
                "Others": "Others",
                "CONTACTUS": "CONTACTUS",
                "LetsCollaborateNow": "Let’s Collaborate Now!",
                "FullName": "Full Name",
                "YourName": "Your Name",
                "Email": "Email",
                "YourEmail": "Your Email",
                "Phone": "Phone",
                "MobileNumber": "Mobile Number",
                "Message": "Message",
                "WriteMessage...": "Write Message...",
                "SendMessage": "Send Message",
                "WelcomeBack": "Welcome Back",
                "signintoaccessyouraccount": "Sign in to access your account",
                "signupyouraccount": "Sign up your account",
                "Merchant": "Merchant",
                "Customer": "Customer",
                "Remember": "Remember",
                "Forgetpassword": "Forget Password ?",
                "LOGINASGUEST": "LOGIN AS GUEST",
                "Loginwith": "Log in with",
                "NewMember": "New Member?",
                "Signupnow": " Sign up now",
                "Bycheckingtheboxyouagreetoour": "By checking the box you agree to our ",
                "Terms": "Terms",
                "Conditions": "Conditions",
                "AllServices": "All Services",
                "Country": "Country",
                "copyright": "Copyright © 2024 AL-Hafeez All rights reserved.",
                "Total Services": "Total Services",
                "Rating": "Rating",
                "AlreadyaMember": "Already a Member?",
                "HowWasYourExperience": "How Was Your Experience",
                "Describeyourexperience": "Describe your experience?",
                "Chocolates": "Chocolates",
                "Price": "Price:$5",
                "Instagramprofilelink": "Instagram profile link",
                "Whatsappnumber": "Whatsapp Number",
                "Share": "Share",
                "Recommended": "Recommended",
                "Theyaresaying": "They are saying....",
                "Mycomment": `For the past five years I’ve been a chef at Tel Aviv restaurants and now decided to go independent. I offer two s
                ervices: dining at my house and food delivery in my area. My expertise is Italian food, French , pizzas, vegan, desserts, and snacks. 
                I live in central Tel Aviv can deliver to Tel-Aviv, Jaffa, Ramat-Gan and Givatayim.`,
                "AccountCreateSuccessfully": "تم إنشاء الحساب بنجاح",
                "AccountCreateSuccessfully": "Account Create Successfully",
                "YourAccounthasbeen": "Your Account has been",
                "CreateSuccessfully": " Create Successfully !",
                "Pleaseentertheverificationcode": "Please enter the verification code",
                "wesendtoyouremail": "we send to your email.",
                "Dontreceiveacode": "Don’t receive a code?",
                "UploadImage": "Upload Image",
                "ProductName": "Product Name",
                "ProductPrice": "Price",
                "Description": "Description",
                "Select Category": "Select Category",
                "SelectSubCategory": "Select Sub Category",
                "Sub Category": "Sub Category",
                "Add Product": "Add Product",
                "recommended service": "recommended service",
                "Tourism&exhibition": "Tourism & exhibition",
                "Weddingplanning": "Wedding planning",
                "Softwaresolutions": "Software solutions",
                "Enteryouremail": "Enter your email",
                "Password": "Password",
                "Full name": "Full name",
                "Valid email": "Valid email",
                "Phone number": "Phone number",
                "EditProfile": "Edit Profile",
                "YourName": "Your Name",
                "First name":"First name",
                "Last name":"Last name",
                "License number":"License number",
                "Enter your email":"Enter your email",
                "Email": "Email",
                "required": "⚠️ Required",
                "OTP is incorrect": "OTP is incorrect",
                "Name is Required": "⚠️ Name is Required",
                "Invalid email": "⚠️ Invalid email",
                "Confirm Password is required": "⚠️ Confirm Password is required",
                "Confirm Password": "Confirm Password",
                "Email is required": "⚠️ Email is required",
                "Phone number must only contain digits": "Phone number must only contain digits",
                "Phone number is too short": "Phone number is too short, it should be at least 10 digits",
                "Phone number is too long": "Phone number is too long, it should be less than 15 digits",
                "Password at least 8 characters": "Password must be at least 8 characters long",
                "Password must contain at least": "Password must contain at least one capital letter, one number, and one special symbol",
                "Message Required": "⚠️ Message is Required",
                "Phone number is required": "⚠️ Phone number is required",
                "Price Required": "⚠️ Price is Required",
                "Description Required": "⚠️ Description is Required",
                "Category Required": "⚠️ Category is  Required",
                "Image is required": "⚠️ Image is required",
                "Password is required": "⚠️ Password is required",
                "Too Short": "⚠️ Too Short!",
                "Something went wrong": "Something went wrong. Try again",
                "Something wrong": "something went wrong",
                "Verify email address": "Verify your email address",
                "Login successfully!": "Login successfully!",
                "Password does not match": "Password does not match",
                "Invalid credentials": "Invalid credentials",
                "Signup Successfully,Please Check your email": "Sign up Successfully,Please Check your email",
                "Validation errors": "Validation errors",
                "OTP sent to your email": "OTP sent to your email",
                "Invalid User": "Invalid User",
                "Invalid OTP": "Invalid OTP",
                "Password Reset Successfully": "Password Reset Successfully",
                "Select a country": "Select a country",
                "No Service Found 404": "No Service Found 404",
                "Subsribe Successfully!": "Subsribe Successfully!",
                "Whatsapp": "Whatsapp",
                "Instagram": "Instagram",
                "Service Created Succesfullys": "Service Created Succesfully",
                "Edit successfully!": "Edit successfully!",
                "Account Verification successfully!": "Account Verification successfully!",
                "OTP resent to your email": "OTP resent to your email",
                "Invalid user type.": "Invalid user type.",
                "The email has already been taken.": "The email has already been taken.",
                "Review Add Successfully": "Review Add Successfully",
                "Contact form submitted successfully!": "Contact form submitted successfully!",
                "Rating must be greater than 0": "Rating must be greater than 0",
                "Notification":"Notification",
                "New Email":"New Email",
                "Old Email":"Old Email",
                "Update":"Update",
                "New Password":"New Password",
                "Old Password":"Old Password",
                "My Favourite":"My Favourite",
                "Rating":"Rating",
                "New":"New",
                "All":"All",
                "Advertisements":"Advertisements",
                "Subscription":"Subscription",
                "To enjoy the privilege of using and adding products, we kindly request you to subscribe with us. By subscribing, you will have access to exclusive benefits such as:":"To enjoy the privilege of using and adding products, we kindly request you to subscribe with us. By subscribing, you will have access to exclusive benefits such as:",
                "Adding new products effortlessly":"Adding new products effortlessly",
                "Enhanced product management features":"Enhanced product management features",
                "Choose the best plan for you":"Choose the best plan for you",
                "Priority customer support":"Priority customer support",
                "Please note that the subscription is valid for one year from the subscription date.":"Please note that the subscription is valid for one year from the subscription date.",
                "Chocolates":"Chocolates",
                "Tourism & exhibition":"Tourism & exhibition",
                "Software solutions":"Software solutions",
                "Wedding planning":"Wedding planning",
                "Email updated":"Email updated",
                "The email field must be a valid email address":"The email field must be a valid email address",
                "Please fill all fields":"Please fill all fields",
                "Register":"Register",
                "instagram":"instagram",
                "rating":"rating",
                "whatsapp":"whatsapp",
                "No data found":"No data found",
                "Addtofavourite":"Add to favourite"
                
            }
        },
        arabic: {
            translation: {
                "Wedding planning":"تحضير للزفاف",
                "Addtofavourite":"أضف إلى المفضلة",
                "Software solutions":"حلول البرمجيات",
                "Chocolates":"الشوكولاتة",
                "Tourism & exhibition":"السياحة والمعارض",
                "Please note that the subscription is valid for one year from the subscription date.":"يرجى ملاحظة أن الاشتراك صالح لمدة سنة واحدة من تاريخ الاشتراك.",
                "Priority customer support":"أولوية دعم العملاء",
                "Enhanced product management features":"ميزات إدارة المنتج المحسنة",
                "Choose the best plan for you":"اختر الخطة الأفضل بالنسبة لك",
                "Register":"يسجل",
                "Adding new products effortlessly":"إضافة منتجات جديدة دون عناء",
                "To enjoy the privilege of using and adding products, we kindly request you to subscribe with us. By subscribing, you will have access to exclusive benefits such as:":"للتمتع بميزة استخدام وإضافة المنتجات، نرجو منك الاشتراك معنا. من خلال الاشتراك، سيكون لديك إمكانية الوصول إلى المزايا الحصرية مثل:",
                "Pay Subscription":"دفع الاشتراك",
                "Subscription":"الاشتراك",
                "Please fill all fields":"لو سمحت أملأ كل الحقول",
                "Advertisements":"إعلانات",
                "All":"الجميع",
                "New":"جديد",
                "My Favourite":"المفضل لدي",
                "Rating":"تقييم",
                "Old Password":"كلمة المرور القديمة",
                "Update":"تحديث",
                "Old Email":"البريد الإلكتروني القديم",
                "New Email":"بريد إلكتروني جديد",
                "Notification":"إشعار",
                "Favorite":"مفضل",
                "Change Password":"تغيير كلمة المرور",
                "Change Email":"تغيير الايميل",
                "Licence Number":"رقم الرخصة",
                "Settings":"إعدادات",
                "SETTINGS":"إعدادات",
                "Enter your email":"أدخل بريدك الإلكتروني",
                "First name":"الاسم الأول",
                "Last name":"اسم العائلة",
                "License number":"رقم الرخصة",
                "forgotPassword": "هل نسيت كلمة السر",
                "home": "الصفحة الرئيسية",
                "aboutUs": "معلومات عنا",
                "category": "فئة",
                "contactus": "اتصل بنا",
                "profile": "حساب تعريفي",
                "addProduct": "أضف منتج",
                "ReadMore": "اقرأ أكثر",
                "logout": "تسجيل خروج",
                "signup": "اشتراك",
                "login": "تسجيل الدخول",
                "categories": "فئات",
                "popularcategories": "الفئات الشعبية",
                "selectsubcategory": "حدد فئة فرعية",
                "products": "حدد فئة فرعية",
                "newarrival": "قادم جديد",
                "viewmore": "عرض المزيد",
                "whychooseus": "لماذا أخترتنا",
                "vendor": "بائع",
                "featuredProviders": "مقدمي الخدمات المميزين",
                "563": "(٥٦٢)",
                "services": "خدمات",
                "rating": "التقييم",
                "minutes": "دقائق",
                "UsefulLink": "رابط مفيد",
                "AboutUs": "من نحن",
                "ContactUs": "اتصل بنا",
                "Login": "تسجيل الدخول",
                "Category": "فئة",
                "Contact Us": "اتصل بنا",
                "Signup": "اشتراك",
                "Rating": "التقييم",
                "Privacy Policy": "سياسة الخصوصية",
                "Profile": "حساب تعريفي",
                "Edit Profile": "تعديل الملف الشخصي",
                "Verification Code": "رمز التحقق",
                "Account Create Successful": "تم إنشاء الحساب بنجاح",
                "About Us": "معلومات عنا",
                "Signup": "التسجيل",
                "Services": "الخدمات",
                "OurServices": "خدماتنا",
                "Renting": "تأجير",
                "Consultation": "استشارة",
                "Financial&realstates": "الخدمات المالية والعقارات",
                "Designandadvertisement": "التصميم والإعلان",
                "Technologyservices": "خدمات التكنولوجيا",
                "Newsletter": "النشرة الإخبارية",
                "Subscribe us for latest update.": "اشترك للحصول على آخر التحديثات.",
                "Enter your email": "أدخل بريدك الإلكتروني",
                "OurTeam": "فريقنا",
                "StayInLoop": "البقاء في الحلقة",
                "aboutuspara": "هل ترغب في البقاء محدثًا دون عناء؟ قم بالتسجيل باستخدام بريدك الإلكتروني لتلقي آخر الأخبار والتحديثات لدينا",
                "EmailAddress": "عنوان البريد الإلكتروني",
                "continue": "يكمل",
                "Search": "يبحث",
                "Price": "سعر",
                "Qunatity": "كمية",
                "Chocalates": "الشوكولاتة",
                "Savory": "لذيذا",
                "Administration": "إدارة",
                "Others": "آحرون",
                "CONTACTUS": "اتصل بنا",
                "LetsCollaborateNow": "دعونا نتعاون الآن!",
                "FullName": "الاسم الكامل",
                "YourName": "اسمك",
                "Email": "البريد الإلكتروني",
                "YourEmail": "بريدك الإلكتروني",
                "Phone": "الهاتف",
                "MobileNumber": "رقم الجوال",
                "Message": "الرسالة",
                "WriteMessage...": "اكتب الرسالة...",
                "SendMessage": "إرسال الرسالة",
                "WelcomeBack": "مرحبًا بعودتك",
                "signintoaccessyouraccount": "سجل الدخول ليمكنك الوصول لحسابك",
                "Rating must be greater than 0": "يجب أن يكون التقييم أكبر من 0",
                "signupyouraccount": "قم بالتسجيل في حسابك",
                "Merchant": "تاجر",
                "Customer": "عميل",
                "Remember": "يتذكر",
                "Forgetpassword": "نسيت كلمة المرور؟",
                "LOGINASGUEST": "تسجيل الدخول كضيف",
                "Loginwith": "تسجيل الدخول مع",
                "NewMember": "عضو جديد؟",
                "Signupnow":"أفتح حساب الأن",
                "Bycheckingtheboxyouagreetoour": "من خلال تحديد المربع فإنك توافق على لدينا",
                "Terms": " شروط",
                "Conditions": "شروط",
                "This merchant doesn't have any products.": "هذا التاجر ليس لديه أي منتجات.",
                "and": "و",
                "AlreadyaMember": "عضوا فعلا؟",
                "HowWasYourExperience": "كيف كانت تجربتك",
                "Describeyourexperience": "اوصف تجربتك؟",
                "Country": "دولة",
                "copyright": "حقوق النشر © 2024 الحفيظ جميع الحقوق محفوظة.",
                "Total Services": "إجمالي الخدمات",
                "Rating": "تقييم",
                "Star": "نجمة",
                "Submit": "يُقدِّم",
                "Chocolates": "الشوكولاتة",
                "Price": "السعر: 5 دولار",
                "John": "جون",
                "Instagramprofilelink": "رابط الملف الشخصي على الانستقرام",
                "Whatsappnumber": "رقم واتس اب",
                "Share": "يشارك",
                "Recommended": "مُستَحسَن",
                "Theyaresaying": "....يقولون",
                "About me": "ْعَنِّي",
                "Mycomment": `على مدى السنوات الخمس الماضية كنت أعمل طاهياً في مطاعم تل أبيب، وقررت الآن 
                أن أصبح مستقلاً. أقدم خدمتين: تناول الطعام في منزلي وتوصيل الطعام في منطقتي. خبرتي هي الطعام الإيطالي والفرنسية والبيتزا والنباتية
                 والحلويات والوجبات الخفيفة. أعيش في وسط تل أبيب ويمكنني التوصيل إلى تل أبيب ويافا ورمات غان وجفعتايم.`,
                "AccountCreateSuccessfully": "تم إنشاء الحساب بنجاح",
                "YourAccounthasbeen": "لقد كان حسابك",
                "CreateSuccessfully": "تم الإنشاء بنجاح",
                "Pleaseentertheverificationcode": "الرجاء إدخال رمز التحقق",
                "wesendtoyouremail": "نرسل إلى البريد الإلكتروني الخاص بك",
                "Dontreceiveacode": "لا تحصل على رمز؟",
                "Resend": "إعادة إرسال",
                "Confirm": "يتأكد",
                "UploadImage": "تحميل الصور",
                "ProductName": "اسم المنتج",
                "ProductPrice": "سعر",
                "Description": "وصف",
                "Select Category": "اختر الفئةy",
                "SelectSubCategory": "حدد الفئة الفرعية",
                "Sub Category": "تصنيف فرعي",
                "Add Product": "أضف منتج",
                "AllServices": "جميع الخدمات",
                "Add Products / Service": "إضافة المنتجات / الخدمة",
                "recommended service": "الخدمة الموصى بها",
                "Tourism&exhibition": "السياحة والمعارض",
                "Weddingplanning": "تحضير للزفاف",
                "Softwaresolutions": "حلول البرمجيات",
                "Enteryouremail": "أدخل بريدك الإلكتروني",
                "Password": "كلمة المرور",
                "Full name": "الاسم الكامل",
                "Valid email": "بريد إلكتروني صالح",
                "Phone number": "رقم التليفون",
                "Strong Password": "كلمة سر قوية",
                "Country": "دولة",
                // "OTP": "مكتب المدعي العام",
                "OTP": "رمز التفعيل",
                "Email updated":"تم تحديث البريد الإلكتروني",
                "The email field must be a valid email address":"يجب أن يكون حقل البريد الإلكتروني عنوان بريد إلكتروني صالحًا",
                "New Password": "كلمة المرور الجديدة",
                "EditProfile": "تعديل الملف الشخصي",
                "YourName": "اسمك",
                "Email": "بريد إلكتروني",
                "Save": "يحفظ",
                "required": "⚠️ مطلوب",
                "Name is Required": "⚠️ مطلوب اسم",
                "Invalid email": "⚠️ البريد الإلكتروني غير صالح",
                "Email is required": "⚠️ البريد الإلكتروني مطلوب",
                "OTP is incorrect": "كلمة المرور لمرة واحدة غير صحيحة",
                "Confirm Password is required": "⚠️ تأكيد كلمة المرور مطلوب",
                "Confirm Password": "تأكيد كلمة المرور",
                "Phone number must only contain digits": "يجب أن يحتوي رقم الهاتف على أرقام فقط",
                "Phone number is too short": "رقم الهاتف قصير جدًا، ويجب ألا يقل عن 10 أرقام",
                "Phone number is too long": "رقم الهاتف طويل جدًا، يجب أن يكون أقل من 15 رقمًا",
                "Password at least 8 characters": "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل",
                "Password must contain at least": "يجب أن تحتوي كلمة المرور على حرف كبير واحد ورقم واحد ورمز خاص واحد على الأقل",
                "Phone number is required": "⚠️ رقم الهاتف مطلوب",
                "Message Required": "⚠️الرسالة المطلوبة",
                "Price Required": "⚠️ السعر مطلوب",
                "Description Required": "⚠️ الوصف مطلوب",
                "Category Required": "⚠️ الفئة مطلوبة",
                "Image is required": "⚠️ الصورة مطلوبة",
                "Password is required": "⚠️ كلمة المرور مطلوبة",
                "Too Short": "⚠️ قصير جدًا!",
                "Something went wrong": "هناك خطأ ما. حاول ثانية",
                "Something wrong": "هناك خطأ ما",
                "Verify email address": "تحقق من عنوان بريدك الإلكتروني",
                "Login successfully!": "تسجيل الدخول بنجاح!",
                "Password does not match": "كلمة السر غير متطابقة",
                "Invalid credentials": "بيانات الاعتماد غير صالحة",
                "Signup Successfully,Please Check your email": "تم التسجيل بنجاح، يرجى التحقق من بريدك الإلكتروني",
                "Validation errors": "أخطاء التحقق من الصحة",
                "OTP sent to your email": "تم إرسال OTP إلى بريدك الإلكتروني",
                "Invalid User": "مستخدم غير صالح",
                "Invalid OTP": "كلمة المرور لمرة واحدة غير صالحة",
                "Password Reset Successfully": "تمت إعادة تعيين كلمة المرور بنجاح",
                "Select a country": "اختر دولة",
                "No Service Found 404": "لم يتم العثور على خدمة 404",
                "Subsribe Successfully!": "تم الاشتراك بنجاح!",
                "Whatsapp": "واتس اب",
                "Instagram": "انستغرام",
                "Service Created Succesfully": "تم إنشاء الخدمة بنجاح",
                "Edit successfully!": "تم التعديل بنجاح!",
                "Account Verification successfully!": "تم التعديل بنجاح!",
                "OTP resent to your email": "تم إرسال OTP إلى بريدك الإلكتروني",
                "Invalid user type.": "نوع المستخدم غير صالح.",
                "The email has already been taken.": "البريد الإلكتروني تم أخذه",
                "Review Add Successfully": "تمت مراجعة الإضافة بنجاح",
                "Contact form submitted successfully!": "تم إرسال نموذج الاتصال بنجاح!",
                "instagram":"انستغرام",
                "rating":"تقييم",
                "whatsapp":"واتس اب",
                "heart":"قلب",
                "No data found":"لاتوجد بيانات"
            }
        }
    },
    lng: "english",
    fallbackLng: "english",
    interpolation: {
        escapeValue: false,
    },
});
export default i18n;