import React, { useContext, useEffect } from 'react'
import AllBanners from '../Components/AllBanners'
import Allcards from '../Components/Allcards'
import Footer from '../Components/Footer'
import DrawerAppBar from '../Components/AppHeader'
import TopHeader from '../Components/TopHeader'
import { useNavigate } from 'react-router-dom'
import config from "../Services/Config.json"
import { MainUsersContext } from '../Context/UserContext'

const Login = () => {
    const { UserCredential } = useContext(MainUsersContext);
    const navigate = useNavigate()

    useEffect(() => {
        if (UserCredential?.id) {
            navigate(`/${config.ddemoss}`);
        }
    }, [UserCredential]);



    return (
        <div>

            <TopHeader />
            <DrawerAppBar />
            <AllBanners title={"Login"} />
            <div className='Card_container'>
                <Allcards forpage={"login"} />
            </div>
            <Footer />
        </div>
    )
}

export default Login