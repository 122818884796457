import { createContext, useState } from "react";

export const MainUsersContext = createContext();

function UserContext({ children }) {
    const [UserCredential, setUserCredential] = useState();



    const HandleUserCredential = (UserData, token) => {
        if (UserData) {
            setUserCredential({ ...UserData, token })
        } else {
            setUserCredential("")
        }
    }

    return (
        <MainUsersContext.Provider value={{ HandleUserCredential, UserCredential }}>
            {children}
        </MainUsersContext.Provider>

    );
}

export default UserContext;