import React, { useContext, useEffect } from 'react'
import AllBanners from '../Components/AllBanners'
import Allcards from '../Components/Allcards'
import Footer from '../Components/Footer'
import DrawerAppBar from '../Components/AppHeader'
import TopHeader from '../Components/TopHeader'
import { useNavigate } from 'react-router-dom'
import config from "../Services/Config.json"
import { MainUsersContext } from '../Context/UserContext'
import { useTranslation } from 'react-i18next'

const Signup = () => {
    const navigate = useNavigate()
    const { UserCredential } = useContext(MainUsersContext);
    const { t } = useTranslation();
    useEffect(() => {
        if (UserCredential?.id) {
            navigate(`/${config.ddemoss}`);
        }
    }, [UserCredential]);
    return (
        <div>
            <TopHeader />
            <DrawerAppBar />
            <AllBanners title={t("Register")} />
            <div className='Card_container'>
                <Allcards forpage="signup" />
            </div>
            <Footer />
        </div>
    )
}

export default Signup