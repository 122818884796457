import React, { useEffect, useState } from 'react'
import LeftSideBar from '../Components/Profile/LeftSideBar'
import TopHeader from '../Components/TopHeader'
import AppHeader from "../Components/AppHeader";
import AllBanners from '../Components/AllBanners';
import Footer from "../Components/Footer";
import NotificationCom from '../Components/NotificationCom';
import AppLoader from '../Components/AppLoader';
const Notification = () => {
  const [Isloader,setIsLoader] = useState(true)



  useEffect(() => {
  setTimeout(() => {
    setIsLoader(false)
  }, 1000);


  }, [Isloader])

  return (
    <div>
      <AppLoader Isloader={Isloader}/>
        <TopHeader />
        <AppHeader />
        <AllBanners title={"Notification"} />
        <div className='flex flex-direction-column'>
      <LeftSideBar/>
      <NotificationCom/>
      
      </div>
      <Footer/>
    </div>
  )
}

export default Notification
