import React, { useContext, useEffect, useState } from "react";
import TopHeader from "./TopHeader";
import AppHeader from "./AppHeader";
import AllBanners from "./AllBanners";
import Footer from "./Footer"
import useFetch from "../CustomHooks/UseFetch";
import { languageContextProvider } from "../Context/LangaugeContext";

function PrivacyPolicy() {

  const { data: privacy_content, loading, error } = useFetch("privacy-content");
  const { langName } = useContext(languageContextProvider);

  const [PrivacyData, setPrivacyData] = useState();
  useEffect(() => {
    if (privacy_content) {
      setPrivacyData(privacy_content?.data)
    }
  }, [privacy_content]);


  return (
    <div className="privacypolicy">
      <TopHeader />
      <AppHeader />
      <AllBanners title={"Privacy Policy"} />
      <div className="container mt-5">
        <div dangerouslySetInnerHTML={{ __html: PrivacyData && langName == "arabic" ? PrivacyData?.arabic_privacy_content : PrivacyData?.privacy_content }} />
      </div>
      <Footer />
    </div>

  );
}

export default PrivacyPolicy;
