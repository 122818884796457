import AllBanners from "../Components/AllBanners";
import DrawerAppBar from "../Components/AppHeader";
import CardsTwo from "../Components/CardsTwo";
import Footer from "../Components/Footer";
import TopHeader from "../Components/TopHeader";
import config from "../Services/Config.json"
import { MainUsersContext } from '../Context/UserContext'
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
function Acountcreate() {


    const { UserCredential } = useContext(MainUsersContext);
    const navigate = useNavigate()

    useEffect(() => {
        if (UserCredential?.id) {
            navigate(`/${config.ddemoss}`);
        }
    }, [UserCredential]);

    return (
        <div>
            <TopHeader />
            <DrawerAppBar />
            <AllBanners title={"Account Create Successful"} />
            <div className='Card_container'>
                <CardsTwo tittle={"accountcreate"} />
            </div>
            <Footer />
        </div>
    );
}

export default Acountcreate;