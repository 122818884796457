import { useContext, useEffect, useRef, useState } from "react"
import { Button } from '@mui/material'
import ProductCom2 from './Productcom2';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import config from "../../Services/Config.json"
import useFetch from "../../CustomHooks/UseFetch";
import { Select } from 'antd';
import { getTokenSession } from "../../MainFunction";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { toast } from "react-hot-toast";
import { languageContextProvider } from "../../Context/LangaugeContext";

gsap.registerPlugin(ScrollTrigger);

function ProductCom() {

    const [fileInputVisible, setFileInputVisible] = useState(false);
    const { data: categories, loading, error } = useFetch("categories");
    const { langName } = useContext(languageContextProvider);
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [SelectedCategory, setSelectedCategory] = useState();
    const [SelectedSubCategory, setSelectedSubCategory] = useState();
    const [SubCategoriesList, setSubCategoriesList] = useState([]);
    const authentication = getTokenSession();

    const handleImageClick = () => {
        // Show the file input
        setFileInputVisible(true);
        if (fileInputRef.current) {
            // Programmatically trigger the file input
            fileInputRef.current.click();
        }
    };

    const option = categories?.length > 0 && Array.isArray(categories) && categories.map(category => ({
        label: langName == "arabic" ? category?.arabic_name : category?.name,
        value: category.id
    }))
    useEffect(() => {

    }, [SelectedCategory]);


    const handleFileChange = (e, formik) => {
        const selectedFile = e.target.files[0];
        setSelectedImage(URL.createObjectURL(selectedFile));
        formik.setFieldValue('image', selectedFile);
        setFileInputVisible(false);
    };

    const initialValues = {
        name: '',
        price: '',
        description: '',
        image: selectedImage,
    }
    const validationSchemaProduct = Yup.object().shape({
        name: Yup.string().required(t("Name is Required")),
        price: Yup.string().required(t("Price Required")),
        description: Yup.string().required(t("Description Required")),
        image: Yup.mixed().required(t('Image is required')).test('fileSize', 'File size is too large', (value) => {
            return value && value.size <= 1024 * 1024; // Adjust the size limit as needed
        }),
    })


    const onChange = (value) => {
        setSelectedCategory(value)
        if (value) {
            axios.get(`${config.apiEndPoint}subcategories/${value}`, {
                headers: {
                    Authorization: `Bearer ${authentication}`,
                },
            })
                .then((response) => {
                    if (response?.data?.message) {
                        setSubCategoriesList(response?.data?.data?.length > 0 && Array.isArray(response?.data?.data) && response?.data?.data?.map(category => ({
                            label: langName == "arabic" ? category?.arabic_name : category?.name,
                            value: category?.id
                        })))
                    }
                })
                .catch((error) => {
                });
        }

    };
    const onSearch = (value) => {
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onChangeSub = (value) => {
        setSelectedSubCategory(value)
    };
    const onSearchSub = (value) => {
    };

    // Filter `option.label` match the user type `input`
    const filterOptionSub = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());



    const AddProduct = (formik) => {
        if (Object.values(formik?.values).some(value => value === null || value === "")) {
            return;
        } else {
            if (SelectedCategory && SelectedSubCategory) {
                const formData = new FormData();
                formData.append("name", formik.values.name)
                formData.append("price", formik.values.price)
                formData.append("image", formik.values.image)
                formData.append("description", formik.values.description)
                formData.append("category_id", SelectedCategory)
                formData.append("subcategory_id", SelectedSubCategory)
                axios.defaults.headers = {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${authentication}`,
                };
                axios.post(`${config.apiEndPoint}service/add`, formData)
                    .then((response) => {
                        if (response?.data) {
                            formik.resetForm();
                            setSelectedImage("")
                            setSelectedSubCategory("")
                            setSelectedCategory("")
                            toast.success(t(response?.data?.message), {
                                icon: '👏',
                                duration: 5000,
                                style: {
                                    background: '#75c936 ',
                                    color: "#fff",
                                    minWidth: 200
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        // console.log(error, "error");
                    });
            }
        }
    };

    useEffect(() => {
        gsap.set(".productcardmain", { opacity: 0, scale: 0.5, transformOrigin: "center center" });

        ScrollTrigger.create({
            trigger: ".productcardmain",
            start: "top 80%",
            onEnter: () => {
                gsap.to(".productcardmain", {
                    opacity: 1,
                    scale: 1,
                    duration: 0.9,
                    ease: "power1.out",
                });
            },
            once: true,
        });
    }, []);

    useEffect(() => {
        gsap.set(".productcardtwo", { opacity: 0, x: 100 });

        ScrollTrigger.create({
            trigger: ".productcardtwo",
            start: "top 80%",
            onEnter: () => {
                gsap.to(".productcardtwo", {
                    opacity: 1,
                    x: 0,
                    duration: 0.9,
                    ease: "power1.out",
                });
            },
            once: true,
        });
    }, []);

    return (
        <div className="productmain ">
            <div className='productcardmain'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchemaProduct}
                    onSubmit={AddProduct}
                    validateOnChange
                >
                    {(formik) => {
                        // const disableButton = Object.values(formik.values).some(value => value === null || value === "");

                        return (
                            <Form>
                                <div className="lftheader d-flex  gap-4">
                                    <div onClick={handleImageClick} style={{ cursor: "pointer", borderRadius: "unset", border: "unset" }} >
                                        <img style={{ borderRadius: "unset" }} src={selectedImage || require("../../Assets/WebImages/Categoryone.png")} alt="" />
                                    </div>

                                    <span onClick={handleImageClick} style={{ cursor: "pointer" }}>
                                        {t("UploadImage")}
                                    </span>
                                    {fileInputVisible && (
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            accept=".jpeg, .jpg, .png"
                                            onChange={(e) => handleFileChange(e, formik)}
                                            ref={fileInputRef}
                                        />
                                    )}
                                </div>
                                <ErrorMessage name="image" component='div' className='error-message' />
                                <div >
                                    {/* <form action=""> */}
                                    <div className="d-flex res-productcardmain  my-3 gap-3">
                                        <div className="each-input">
                                            <Field type="text" name="name" placeholder={t("ProductName")} />
                                            <ErrorMessage name="name" component="div" className="error text-danger" />
                                        </div>
                                        <div className='each-input'>
                                            <Field type="text" name="price" placeholder={t("ProductPrice")} />
                                            <ErrorMessage name="price" component="div" className="error text-danger" />

                                        </div>
                                    </div>
                                    <div className='my-3 w-100'>
                                        <Field as="textarea" name="description" placeholder={t("Description")} />
                                        <ErrorMessage name="description" component="div" className="error text-danger" />
                                    </div>
                                    <div className='d-flex mt-3 res-productcardmain mb-5 gap-3'>
                                        {/* <div className='each-input'>
                                            <Field type="text" name="selectctegory" placeholder={t("Select Category")} />
                                            <ErrorMessage name="selectctegory" component="div" className="error text-danger" />
                                        </div> */}
                                        <div className='each-input'>
                                            <Select
                                                showSearch
                                                placeholder={t("Select Category")}
                                                optionFilterProp="children"
                                                value={SelectedCategory}
                                                onChange={onChange}
                                                onSearch={onSearch}
                                                filterOption={filterOption}
                                                options={option ? option : []}
                                            />
                                        </div>
                                        <div className='each-input'>
                                            {/* <Button className='contactbtn' variant="contained" size="medium"  >
                                                {t("Sub Category")}

                                            </Button> */}
                                            <Select
                                                showSearch
                                                placeholder={t("Sub Category")}
                                                optionFilterProp="children"
                                                onChange={onChangeSub}
                                                onSearch={onSearchSub}
                                                filterOption={filterOptionSub}
                                                value={SelectedSubCategory}
                                                options={Array.isArray(SubCategoriesList) && SubCategoriesList}
                                            />
                                        </div>
                                    </div>
                                    <div className='w-50'>
                                        <Button className='productbtn' variant="contained" size="medium"
                                            type="submit" onClick={() => AddProduct(formik)} >
                                            {t("Add Product")}
                                        </Button>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            <div className='productcardtwo'>
                <ProductCom2 />
            </div>
        </div>
    );
}

export default ProductCom;