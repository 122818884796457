import React, { useContext, useEffect, useState } from 'react'
import TopHeader from '../TopHeader'
import AppHeader from '../AppHeader'
import AllBanners from '../AllBanners'
import RatingCard from './RatingCard'
import Footer from '../Footer'
import config from '../../Services/Config.json'
import { MainUsersContext } from '../../Context/UserContext'
import { useNavigate } from 'react-router-dom'
import { getTokenSession } from '../../MainFunction'

function Rating() {
  const { UserCredential } = useContext(MainUsersContext);
  const navigate = useNavigate()
  
  const authentication = getTokenSession();
  useEffect(() => {
    if (UserCredential == undefined && (authentication == null || !authentication || UserCredential?.id)) {
      navigate(`/${config.ddemoss}login`);
    }
  }, [UserCredential]);

  return (
    <div>
      <TopHeader />
      <AppHeader />
      <AllBanners title={"Rating"} />
      <RatingCard />
      <Footer />
    </div>
  )
}

export default Rating
