import React from 'react'
import RightSliderImageCom from './RightSliderImageCom'
import { useTranslation } from 'react-i18next';

const RightSliderCom = () => {
  const { t } = useTranslation();

  return (
    <div className='RightSliderCom'>
        <h3>{t("recommended service")}</h3>
        <RightSliderImageCom/>
    </div>
  )
}

export default RightSliderCom
