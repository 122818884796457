import React, { useState } from "react";
import openeyes from "../Assets/WebImages/eyes.png";
import closeyes from "../Assets/WebImages/eye-slash.png";
import config from "../Services/Config.json";
import { getTokenSession } from "../MainFunction";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const ChangePasswordCom = ({ data }) => {
  const [passwordnewVisible, setPasswordnewVisible] = useState(false);
  const [passwordconfirmVisible, setPasswordconfirmVisible] = useState(false);
  const { t } = useTranslation();
  // const [passwordoldVisible, setPasswordoldVisible] = useState(false);
  const [Model, setModel] = useState();
  const authentication = getTokenSession();
  // console.log(authentication,data?.id);
  function toggleneweye() {
    setPasswordnewVisible(!passwordnewVisible);
  }
  // function toggleoldeye() {
  //     setPasswordoldVisible(!passwordoldVisible);
  // }

  function togglconfirmeeye() {
    setPasswordconfirmVisible(!passwordconfirmVisible);
  }
  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };
  const submitpassword = async () => {
    if (Model && Object.keys(Model).length === 2) {
      const password = Model.password;
      if (!validatePassword(password)) {
        toast.error(
          "Password must be at least 8 characters long, contain at least one number, one special character, and one uppercase letter."
        );
        return;
      }

      const response = await axios.post(
        `https://admin.q8hafeez.com/api/update-password`,
        Model,
        {
          headers: {
            Authorization: `Bearer ${authentication}`,
          },
        }
      );

      if (response) {
        if (response?.data?.message === "Password updated") {
          toast.success(response?.data?.message);
          return;
        }
        if (response?.data?.message === "Validation errors") {
          toast.error(response?.data?.message);
          return;
        }
      }
    } else {
      toast.error(t("Please fill all fields"));
    }
    // toast.error(t("Please fill all fields"));
  };
  const fillmodel = (key, value) => {
    setModel((prevModel) => ({
      ...prevModel,
      [key]: value,
    }));
  };


  return (
    <div className="ChangeEmailCom">
      <div className="ChangeEmailCom-div">
        <h3>{t("Change Password")}</h3>
        <p style={{ color: "#418e30" }} className="mb-0">
          {t("Old Password")}
        </p>
        <div className="div-input-email-page" style={{ position: "relative" }}>
          <input
            className="input-email-page"
            placeholder="Old Password"
            value="12345678"
            disabled
            type="password"
          />
          {/* <img src={passwordoldVisible ? openeyes : closeyes} alt=""  onClick={toggleoldeye} /> */}
        </div>
        {/* <div className='div-input-email-page'>
                    <input className='input-email-page' placeholder='Old Password' type="text" />
                </div> */}
        <div className="div-input-email-page" style={{ position: "relative" }}>
          <input
            className="input-email-page"
            placeholder={t("New Password")}
            onChange={(e) => fillmodel("password", e.target.value)}
            type={passwordnewVisible ? "text" : "password"}
          />
          <img
            src={passwordnewVisible ? openeyes : closeyes}
            alt=""
            onClick={toggleneweye}
          />
        </div>
        <div className="div-input-email-page" style={{ position: "relative" }}>
          <input
            className="input-email-page"
            placeholder={t("Confirm Password")}
            type={passwordconfirmVisible ? "text" : "password"}
            onChange={(e) => fillmodel("password_confirmation", e.target.value)}
          />
          <img
            src={passwordconfirmVisible ? openeyes : closeyes}
            alt=""
            onClick={togglconfirmeeye}
          />
        </div>
        <div className="div-input-email-page">
          <button className="button-input" onClick={submitpassword}>
            {t("Change Password")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordCom;
