import AllBanners from "../Components/AllBanners";
import Footer from "../Components/Footer";
import ProductCom from "../Components/Productscom/ProductCom";
import Appheader from "../Components/AppHeader";
import TopHeader from "../Components/TopHeader";
import config from "../Services/Config.json"
import { MainUsersContext } from '../Context/UserContext'
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { getTokenSession } from "../MainFunction";

import api from "../Services/httpService";

function AddProduct() {
    const { UserCredential } = useContext(MainUsersContext);
    const navigate = useNavigate()
    const authentication = getTokenSession();

    useEffect(() => {
        if (UserCredential?.type == "customer") {
            navigate(`/${config.ddemoss}`);
        }
        if (UserCredential == undefined && (authentication == null || !authentication || UserCredential?.id)) {
            navigate(`/${config.ddemoss}login`);
        }
    }, [UserCredential]);

    useEffect(() => {
        const fetchUserSubscription = async () => {
          const userSubscription = await api.get(
            `${config.apiEndPoint}has-subscription`,
            {
              headers: {
                  Authorization: `Bearer ${getTokenSession()}`
              }
            }
          )

          document.body.style.overflow = 'unset';
    
          let { subscription } = userSubscription.data.data
    
          if (!subscription) {
            navigate('/paysubscription');
          }
        }
    
        fetchUserSubscription();
      }, []);

    return (
        <>
            <TopHeader />
            <Appheader />
            <AllBanners title={"Add Products / Service"} />
            <ProductCom />
            <Footer />
        </>

    );
}

export default AddProduct;